.nieuws-overview {

    .title-wrap {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        h2 {
            margin: 0;
        }

        .read-more {
            position: relative;
            font-size: 14px;
            /* text-transform: uppercase; */
            color: var(--color-green);
            /* font-style: italic; */
            font-weight: 700;
            padding-left: 40px;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
                color: #000;
            }

            &::before {
                position: absolute;
                top: 50%;
                left: 16px;
                content: '';
                display: block;
                height: 6px;
                width: 6px;
                background-color: var(--color-green);
                border-radius: 100%;
                transform: translateY(-50%);
            }
        }
    }

    .row {
        margin: 0 -20px;

        [class^='col-'] {
            padding: 0 20px;
        }
    }
}

.nieuws-tile {
    margin-bottom: 32px;

    a {
        color: inherit;
        text-decoration: inherit;

        &:hover {

            .image img {
                transform: scale(1.05);
            }

            .read-more {
                text-decoration: underline;
            }
        }
    }

    .image {
        overflow: hidden;
        margin-bottom: 18px;

        img {
            display: block;
            transition: .2s;
        }
    }

    .date {
        font-size: 13px;
        color: #696969;
        margin-bottom: 8px;
    }

    .title {
        text-transform: none;
        font-weight: 700;
        font-size: 24px;
    }

    .excerpt {
        font-size: 15px;
        color: #696969;
    }

    .read-more {
        /* text-transform: uppercase; */
        color: var(--color-green);
        /* font-style: italic; */
        font-weight: 700;
        font-size: 15px;
    }
}