.tarieven-overzicht-section {
    margin: 0;
    padding: 40px 0;
    overflow: hidden;

    & .content-wrap {

        /* & .header {

            & h2 {
                position: relative;
                display: block;
                font-size: 1.4em;
                margin-bottom: 40px;
            }
        } */

        .header {
            display: flex;
            justify-content: space-between;
            /* margin-bottom: 40px; */
            margin: 0 0 -22px;

            &.header--club .select-wrap {
                width: 100%;
            }

            .select-wrap {
                width: 49%;

                h3 {
                    font-size: 1.2em;
                }

                .custom-select {
                    border: none;
                    width: 100%;
                    padding: 0;
                    border-radius: 5px;
                    overflow: hidden;
                    margin-bottom: 0;
                    background: #f5f3ed url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
                    background-repeat: no-repeat, repeat;
                    background-position: right .7em top 50%, 0 0;
                    background-size: .65em auto, 100%;

                    & select {
                        cursor: pointer;
                        padding: 12px 16px;
                        background-color: #fff;
                        width: 100%;
                        border: none;
                        box-shadow: none;
                        background: transparent;
                        font-family: var(--base-font-family);
                        background-image: none;
                        -webkit-appearance: none;
                    }
                }
            }
        }

        .tarieven-wrap {
            padding: 50px 30px 20px;
            margin: 0 -30px;
            border-radius: 22px;
            border: solid 3px #f5f3ed;
            background-color: white;

            .tarieven-title {
                font-size: 22px;
                margin-bottom: 14px;
            }

            .actie-banner {
                margin-bottom: 44px;
                background-color: #A8D6ED;
                border-radius: 6px;
                padding: 8px 12px;
            }
        }

        & .tarieven {
            position: relative;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            margin: 0 -6px;
            min-height: 100px;

            .loader-img {
                position: absolute;
                z-index: 1;
                width: 32px;
                top: 10px;
                left: 12px;
                animation: rotation 2s infinite linear;
            }

            @keyframes rotation {
                from {
                  transform: rotate(0deg);
                }
                to {
                  transform: rotate(-359deg);
                }
            }

            .tarieven-tile-wrap {
                position: relative;
                z-index: 2;
                width: 100%;
                padding: 0 6px;
            }

            & .tarieven-tile {
                position: relative;
                display: flex;
                flex-direction: column;
                /* width: 100%; */
                margin-bottom: 30px;
                background-color: #f5f3ed;
                padding: 42px 22px 22px;
                border-radius: 5px;

                .top-label {
                    position: absolute;
                    top: 0;
                    left: 0;
                    transform: translateY(-50%);
                    width: 100%;
                    display: block;
                    text-align: center;

                    .inner-wrap {
                        font-family: var(--heading-font-family);
                        /* text-transform: uppercase; */
                        font-weight: 300;
                        line-height: 1.2;
                        letter-spacing: 1px;
                        display: inline-block;
                        text-align: center;
                        background-color: #7c8f77;
                        color: #fff;
                        padding: 6px 12px;
                        border-radius: 5px;
                        font-size: 13px;
                    }
                }

                & .top {

                    & h3 {
                        font-size: 1.8em;
                        margin: 0 0 .7em;

                        .abbo-sub {
                            margin-top: 6px;
                            font-size: 17px;
                            font-weight: 400;
                            font-family: var(--base-font-family);
                        }
                    }

                    & ul {
                        list-style-type: none;
                        margin: 0 0 24px;
                        padding: 0;

                        & li {
                            position: relative;
                            padding-left: 28px;
                            margin-bottom: 6px;
                            font-size: 15px;

                            &::before {
                                content: '';
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 28px;
                                height: 100%;
                                background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='check' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%237C8F77' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z' class=''%3E%3C/path%3E%3C/svg%3E");
                                background-size: 24px 1em;
                                background-position: 0px 4px;
                                background-repeat: no-repeat;
                            }
                        }
                    }
                }

                & .bottom {
                    margin-top: auto;

                    & .price-wrap {

                        & .price {
                            display: block;
                            font-size: 2em;
                        }

                        & .price-month {
                            display: block;
                            font-size: 1.3em;
                        }

                        & .abbo {
                            display: block;
                            margin-top: 1rem;
                            font-size: .8em;
                        }
                    }

                    .button {
                        width: 100%;
                    }

                    & .tos-button {
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}

@media (--viewport-sm-min) {
    .tarieven-overzicht-section {
        padding: 60px 0;

        & .content-wrap {
            /* padding: 0 5%; */

            & .tarieven {
                flex-direction: row;

                .tarieven-tile-wrap {
                    width: calc(100% / 3);
                    padding: 0 6px;
                }

                & .tarieven-tile {

                }
            }
        }
    }
}

@media (--viewport-md-min) {
    .tarieven-overzicht-section {
        padding: 80px 0;

        & .content-wrap {

            /* & .header {

                & h2 {
                    font-size: 1.3em;
                    margin-bottom: 44px;
                }
            } */

        .tarieven-wrap {
            padding: 60px 40px 50px;
            margin: 0 -40px;
            border-radius: 22px;

            .tarieven-title {
                font-size: 29px;
                margin-bottom: 19px;
            }

            .actie-banner {
                margin-bottom: 54px;
            }
        }

            & .tarieven {
                flex-direction: row;

                & .tarieven-tile {
                    /* width: 32%; */
                    margin-bottom: 0;

                    & .top {

                        & h3 {
                            font-size: 1.9em;
                            margin: 0 0 0.6em;
                        }

                        & ul {
                            margin: 0 0 40px;
                        }
                    }

                    & .bottom {

                        & .price-wrap {

                            & .price {
                                font-size: 1.95em;
                            }

                            & .price-month {
                                font-size: 1em;
                            }

                            & .abbo {
                                font-size: 14px;
                            }
                        }

                        & .tos-button {
                            margin-top: 20px;
                        }
                    }
                }
            }
        }
    }
}

@media (--viewport-lg-min) {
    .tarieven-overzicht-section {

        & .content-wrap {

            /* & .header {

                & h2 {
                    font-size: 1.4em;
                }
            } */
        }
    }
}


