/* .membership-section {
    .col-xs-12:nth-child(1) .lidmaatschap-single__container {
        background-color: #F5F3ED;
    }
    .col-xs-12:nth-child(2) .lidmaatschap-single__container {
        background-color: #F5F3ED;
    }
    .col-xs-12:nth-child(3) .lidmaatschap-single__container {
        background-color: #F5F3ED;
    }
} */

.lidmaatschap-single__container {
    padding: 25px;
    margin-bottom: 20px;
    background-color: #F5F3ED;
    border-radius: 6px;
}

.lidmaatschap-single__type {
    background-color: #fff;
    padding: 15px 0;
    border-radius: 6px;
}

.lidmaatschap-single__type h3 {
    text-align: center;
    margin: 0;
    font-size: 28px;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
    color: #000;
}

.lidmaatschap-single__type span {
    text-align: center;
    display: block;
    margin: 0;
    color: #000;
    font-size: 16px;
    letter-spacing: 1.5px;
}

.lidmaatschap-single__prijs {
    padding: 30px 0 20px;
}

.lidmaatschap-single__prijs h3 {
    text-align: center;
    margin: 0;
    font-size: 42px;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
    color: #000;
}

.lidmaatschap-single__prijs span {
    text-align: center;
    display: block;
    margin: 0 0 30px;
    color: #000;
    font-size: 16px;
    letter-spacing: 1.5px;
}

.lidmaatschap-single__prijs p {
    text-align: center;
    display: block;
    margin: 0;
    color: #000;
    font-size: 16px;
    letter-spacing: 1.5px;
}

.lidmaatschap-single__button .button {
    width: 100%;
}

@media (--viewport-sm-min) {
    .lidmaatschap-single__container {
        padding: 15px;
    }

    .lidmaatschap-single__type {
        padding: 20px 0;
    }

    .lidmaatschap-single__type h3 {
        font-size: 28px;
    }

    .lidmaatschap-single__type span {
        font-size: 16px;
    }

    .lidmaatschap-single__prijs {
        padding: 20px 0 20px;
    }

    .lidmaatschap-single__prijs h3 {
        font-size: 42px;
    }

    .lidmaatschap-single__prijs span {
        margin: 0 0 20px;
        font-size: 16px;
    }

    .lidmaatschap-single__prijs p {
        font-size: 16px;
        letter-spacing: 2px;
    }
}

@media (--viewport-md-min) {
    .lidmaatschap-single__container {
        padding: 20px;
    }

    .lidmaatschap-single__type {
        padding: 20px 0;
    }

    .lidmaatschap-single__type h3 {
        font-size: 36px;
        letter-spacing: 2px;
    }

    .lidmaatschap-single__type span {
        font-size: 18px;
        letter-spacing: 2px;
    }

    .lidmaatschap-single__prijs {
        padding: 30px 0 20px;
    }

    .lidmaatschap-single__prijs h3 {
        font-size: 48px;
        letter-spacing: 2px;
    }

    .lidmaatschap-single__prijs span {
        margin: 0 0 30px;
        font-size: 18px;
        letter-spacing: 2px;
    }

    .lidmaatschap-single__prijs p {
        font-size: 18px;
        letter-spacing: 2px;
    }
}

@media (--viewport-lg-min) {
    .lidmaatschap-single__container {
        padding: 25px;
    }

    .lidmaatschap-single__type h3 {
        font-size: 42px;
    }

    .lidmaatschap-single__type span {
        font-size: 20px;
    }

    .lidmaatschap-single__prijs {
        padding: 40px 0 20px;
    }

    .lidmaatschap-single__prijs h3 {
        font-size: 52px;
    }

    .lidmaatschap-single__prijs span {
        font-size: 20px;
    }

    .lidmaatschap-single__prijs p {
        font-size: 20px;
    }
}