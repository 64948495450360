.hero__title {
    font-weight: 700;
    font-size: 26px;
    color: white;
    position: relative;
    margin-bottom: 12px;
}

.hero__subtitle {
    font-weight: 300;
    text-transform: none;
    font-size: 16px;
    color: white;
    margin-bottom: 20px;
}

.hero__button {
    margin: unset;
}

@media (min-width: 768px){
    .hero__title {
        width: 720px;
        font-size: 46px;

        &--default {
            margin: .4em 0 12px;
        }

        &--small {
            margin: .8em 0 12px;
        }
    }

    .hero__subtitle {
        font-size: 18px;
    }
}
@media (min-width: 992px){
    .hero__title {
        font-size: 52px;
        /* width: 940px; */
    }

    .hero__subtitle {
        font-size: 22px;
    }
}
@media (min-width: 1200px){
    .hero__title {
        width: 900px;
        font-size: 60px;
    }
}

@media (max-width: 767px){
    .hero__title {
        max-width: 767px;
        width: auto;
        position: inherit;
        margin-top: 0;
    }
}

.kop-overlap {
    position: relative;
    width: 100%
}

.kop-overlap__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    &--default {
        height: 560px;
    }

    &--small {
        height: 420px;
    }
}

@media (max-width: 767px) {
    .kop-overlap__bg {
        display: none
    }
}

@media (min-width: 992px) {
    .kop-overlap__bg {
        display: block;

        &--small {
            height: 460px;
        }
    }
}

.kop-overlap__container {
    /* max-width: 1180px; */
    margin: 0 auto;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    padding-top: 3rem;
}

@media (max-width: 767px) {
    .kop-overlap__container {
        display: block;
        -ms-flex-direction: unset;
        flex-direction: unset;
        padding: 0
    }
}

.kop-overlap__container-left {
    width: 42.37288%
}

@media (max-width: 767px) {
    .kop-overlap__container-left {
        width: 100%
    }
}

.kop-overlap__container-right {
    width: 57.62712%
}

@media (max-width: 767px) {
    .kop-overlap__container-right {
        width: 100%
    }
}

.kop-overlap__video {
    width: 100%;
    height: auto;
    display: block
}

.kop-overlap__image {
    width: 100%;
    height: auto;
    display: block
}

.kop-overlap__image-wrapper {
    position: relative
}

.kop-overlap__media1 .kop-overlap__video {
    display: block
}

.kop-overlap__media1 .kop-overlap__image-wrapper .kop-overlap__cta {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-decoration: none
}

.kop-overlap__media1 .kop-overlap__image-wrapper .kop-overlap__cta .kop-overlap__cta-text {
    display: none;
    text-decoration: none
}

.kop-overlap__media1 .kop-overlap__image-wrapper .kop-overlap__cta:hover {
    text-decoration: none
}

@media (min-width: 1023px) {
    .kop-overlap__media1 .kop-overlap__image-wrapper .kop-overlap__cta.desktop-hover:hover .kop-overlap__cta-text {
        display: inline-block;
        text-decoration: none
    }
}

.kop-overlap__media2 {
    position: relative;

    &--default {
        display: block;
    }

    &--small {
        display: none;
    }
}

.kop-overlap__media2 .kop-overlap__video {
    position: relative;
    top: 0;
    left: 0;
    width: 116%;
    background: #000
}

@media (max-width: 767px) {
    .kop-overlap__media2 .kop-overlap__video {
        width: auto;
        margin: 20px 20px 0;
    }
}

.kop-overlap__media2 .kop-overlap__image-wrapper {
    position: relative;
    top: 0;
    left: 0;
    width: 116%;
    background: #000
}

@media (max-width: 767px) {
    .kop-overlap__media2 .kop-overlap__image-wrapper {
        width: auto;
        margin: 1.5rem
    }
}

.kop-overlap__media2 .kop-overlap__image-wrapper .kop-overlap__cta {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-decoration: none
}

.kop-overlap__media2 .kop-overlap__image-wrapper .kop-overlap__cta .kop-overlap__cta-text {
    display: none;
    text-decoration: none
}

.kop-overlap__media2 .kop-overlap__image-wrapper .kop-overlap__cta:hover {
    text-decoration: none
}

@media (min-width: 1023px) {
    .kop-overlap__media2 .kop-overlap__image-wrapper .kop-overlap__cta.desktop-hover:hover .kop-overlap__cta-text {
        display: inline-block;
        text-decoration: none
    }
}

.kop-overlap__content {
    /* padding-top: 3rem; */
    padding-bottom: 1.5rem;
    font-size: 100%
}

@media (min-width: 767px) and (max-width: 1023px) {
    .kop-overlap__content {
        padding-top: 2rem;
        /* padding-left: 2.5rem; */
        padding-right: 2rem;
        padding-bottom: 2rem
    }
}

@media (min-width: 767px) and (max-width: 1179px) {
    .kop-overlap__content {
        padding-top: 0;
        /* padding-left: 2.5rem; */
        padding-right: 1.5rem
    }
}

@media (max-width: 767px) {
    .kop-overlap__content {
        padding: 1.5rem .6rem;
        text-align: center
    }
}

@media (min-width: 768px) {
    .kop-overlap__content {
        background: transparent !important
    }
}

.kop-overlap__content__logo img {
    width: auto;
    height: auto;
    max-width: 335px;
    max-height: 60px
}

@media (max-width: 767px) {
    .kop-overlap__content__logo img {
        margin: 0.8rem auto 0
    }
}

.kop-overlap__content__header {
    font-size: 3.4375rem;
    letter-spacing: 0.15rem;
    line-height: 1;
    /* text-transform: uppercase; */
    font-weight: 700;
    /* margin: 1rem 0 0.5rem */
}

.kop-overlap__content__header a,
.kop-overlap__content__header a:hover {
    color: inherit;
    text-decoration: none
}

@media (max-width: 1023px) {
    .kop-overlap__content__header {
        font-size: 1.6rem
    }
}

@media (max-width: 767px) {
    .kop-overlap__content__header {
        /* margin: 1rem 0 0.5rem */
    }
}

.kop-overlap__content__text {
    font-size: 1.25rem;
    line-height: 1.25;
    font-weight: 300;
    /* margin: 0.6rem 0 */
}

@media (min-width: 767px) and (max-width: 1023px) {
    .kop-overlap__content__text {
        font-size: 1.125rem;
        letter-spacing: 0
    }
}

@media (max-width: 767px) {
    .kop-overlap__content__text {
        font-size: 1.125rem;
        letter-spacing: 0
    }
}

.kop-overlap__content__footnote {
    font-size: 0.8125rem;
    letter-spacing: 0.5px;
    line-height: 1.4;
    /* margin: 0.6rem 0 1rem */
}

@media (min-width: 767px) and (max-width: 1023px) {
    .kop-overlap__content__footnote {
        font-size: 0.8125rem;
        letter-spacing: 0
    }
}

@media (max-width: 767px) {
    .kop-overlap__content__footnote {
        font-size: 0.8125rem;
        letter-spacing: 0
    }
}

.kop-overlap__content__cta {
    /* margin: 1rem 0 */
}

@media (min-width: 767px) and (max-width: 1023px) {
    .kop-overlap__content__cta {
        /* margin: 1rem 0 */
    }
}

@media (max-width: 767px) {
    .kop-overlap__content__cta {
        /* margin: 1rem 0 */
    }
}

.kop-overlap__content__cta.cta1 {
    display: none
}

.kop-overlap__content__cta.cta1.always-visible {
    display: block
}

@media (max-width: 1023px) {
    .kop-overlap__content__cta.cta1 {
        display: block
    }
}

.kop-overlap__content__cta.cta2 {
    display: block
}

.kop-overlap__carousel {
    position: relative;
    margin: 0 auto 2rem;
    width: 100%;
    max-width: 1180px
}

.kop-overlap__carousel .kop-product-carousel {
    -ms-touch-action: manipulation;
    touch-action: manipulation
}

.kop-overlap__carousel .kop-product-carousel .owl-stage-outer {
    margin: 0 auto 2em;
    width: 100%;
    max-width: 1180px
}

.kop-overlap__carousel .kop-product-carousel .owl-item img {
    width: 99.9%
}

.kop-overlap__carousel .kop-product-carousel .owl-nav {
    -webkit-tap-highlight-color: transparent;
    background: none
}

@media (max-width: 958.98px) {
    .kop-overlap__carousel .kop-product-carousel .owl-nav {
        display: none
    }
}

.kop-overlap__carousel .kop-product-carousel .owl-nav [class*='owl-'] {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    fill: #999;
    height: 100%;
    position: absolute;
    top: 0
}

.kop-overlap__carousel .kop-product-carousel .owl-nav [class*='owl-']:hover {
    cursor: pointer;
    background: none;
    fill: #333;
    text-decoration: none
}

.kop-overlap__carousel .kop-product-carousel .owl-nav .owl-prev {
    left: -80px;
    outline: none
}

.kop-overlap__carousel .kop-product-carousel .owl-nav .owl-next {
    right: -80px;
    outline: none
}

.kop-overlap__carousel .kop-product-carousel .owl-nav .disabled {
    opacity: 0.5;
    cursor: default
}

/*Video*/

.video__play__icon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 3rem;
    height: 3rem;
    margin: -1.5rem auto 0;
    top: 50%;
    opacity: 1;
    pointer-events: none
}

@media (min-width: 1199px) {
    .video__play__icon {
        width: 5rem;
        height: 5rem;
        margin-top: -2.5rem
    }
}

.video__pause__icon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 3rem;
    height: 3rem;
    margin: -1.5rem auto 0;
    top: 50%;
    opacity: 0;
    pointer-events: none
}

@media (min-width: 1199px) {
    .video__pause__icon {
        width: 5rem;
        height: 5rem;
        margin-top: -2.5rem
    }
}

.video__wrapper {
    position: relative;
    line-height: 0
}

.video__wrapper .video__play__icon {
    opacity: 0;
    transition: opacity 0.25s ease-in-out
}

.video__wrapper .video__pause__icon {
    opacity: 0;
    transition: opacity 0.25s ease-in-out
}

.video__wrapper.video-stopped .video__play__icon {
    opacity: 1
}

.video__wrapper.video-stopped .video__pause__icon {
    opacity: 0
}

.video__wrapper.video-playing.mouse-show-pause:hover .video__play__icon {
    opacity: 0
}

.video__wrapper.video-playing.mouse-show-pause:hover .video__pause__icon {
    opacity: 1
}

.video__wrapper.video-paused:hover .video__play__icon {
    opacity: 1
}

.video__wrapper.video-paused:hover .video__pause__icon {
    opacity: 0
}

.kop-promotile .kop-promotile__wrapper .kop-promotile__container .kop-promotile__content .kop-promotile__video-btn .video__launch__icon {
    width: 5em;
    height: 5em
}

@media (max-width: 1438.98px) {
    .kop-promotile .kop-promotile__wrapper .kop-promotile__container .kop-promotile__content .kop-promotile__video-btn .video__launch__icon {
        font-size: 100%
    }
}

@media (max-width: 1198.98px) {
    .kop-promotile .kop-promotile__wrapper .kop-promotile__container .kop-promotile__content .kop-promotile__video-btn .video__launch__icon {
        font-size: 60%
    }
}

@media (max-width: 958.98px) {
    .kop-promotile .kop-promotile__wrapper .kop-promotile__container .kop-promotile__content .kop-promotile__video-btn .video__launch__icon {
        font-size: 1.8vw
    }
}

@media (max-width: 543.98px) {
    .kop-promotile .kop-promotile__wrapper .kop-promotile__container .kop-promotile__content .kop-promotile__video-btn .video__launch__icon {
        font-size: 3.1vw
    }
}

.kop-videocarousel__video__container {
    margin-bottom: 1rem
}

@media (max-width: 543.98px) {
    .kop-videocarousel__video__container {
        margin-bottom: 0
    }
}

.kop-videocarousel__video__frame {
    height: 562.5px
}