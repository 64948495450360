/* Volgens mij niet echt nodig.... */

.body-text-link-cursive {
    font-size: 16px;
    letter-spacing: 1.5px;

    &--default {
        color: #2e4b9b;

        & span {
            transition: .1s;
            display: inline-block;
        }

        &:hover span {
            transform: matrix3d(1, 0, 0, 0, -0.12, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transition: .1s;
        }

    }

    &--black {
        color: #000;

        & span {
            transition: .1s;
            display: inline-block;
        }

        &:hover span {
            transform: matrix3d(1, 0, 0, 0, -0.12, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transition: .1s;
        }

    }

    &--white {
        color: #fff;

        & span {
            transition: .1s;
            display: inline-block;
        }

        &:hover span {
            transform: matrix3d(1, 0, 0, 0, -0.12, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transition: .1s;
        }

    }
}

@media (--viewport-sm-min) {
    .body-text-link-cursive {
        font-size: 20px;
        letter-spacing: 2px;
    }
}