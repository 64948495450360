.center-form {

    .center-form-wrap {
        padding: 40px 30px;
        background-color: #f5f3ed;
    }
}

@media (--viewport-md-min) {
    .center-form {

        .center-form-wrap {
            padding: 60px;
        }
    }
}
