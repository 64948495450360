.bevestigings-pagina__container {
    padding: 20px;
    background-color: #F5F3ED;
    color: #000;
}

.bevestigings-pagina__link {
    color: #000;
    text-decoration: underline;
}

@media (--viewport-sm-min) {
    .bevestigings-pagina__container {
        padding: 40px;
        background-color: #F5F3ED;
    }
}