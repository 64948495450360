.info-list {
    margin: 0 0 25px;
    font-size: 16px;
    letter-spacing: 1.5px;

    & li {
        margin-bottom: 5px;
    }

    &--default {
        padding: 0;
        list-style: none;
    }

    &--checkmark {
        padding-left: 20px;
        list-style-image: url(/dist/images/checkmarc.png);
    }
}

@media (--viewport-sm-min) {
    .info-list {
        margin: 0 0 30px;
        font-size: 16px;

        & li {
            margin-bottom: 10px;
        }
    } 
}

@media (--viewport-md-min) {
    .info-list {
        font-size: 18px;
        letter-spacing: 2px;
    } 
}

/* @media (--viewport-lg-min) {
    .info-list {
        font-size: 20px;
    } 
} */