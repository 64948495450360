.carousel-image {
    display: block;
    height: 260px;
    width: unset;
    margin: 0 7px;
}

.flickity-page-dots {
    bottom: 12px;
}

.flickity-page-dots .dot {
    width: 12px;
    height: 12px;
    margin: 0 6px;
    opacity: 1;
    background: rgba(255, 255, 255, 0.3);
}

.flickity-page-dots .dot.is-selected {
    background: white;
}

@media (--viewport-sm-min) {
    .carousel-image {
        height: 310px;
        margin: 0 10px;
    }

    .flickity-page-dots {
        bottom: 22px;
    }
}

@media (--viewport-md-min) {
    .carousel-image {
        height: 410px;
    }
}

@media (--viewport-lg-min) {
    .carousel-image {
        height: 500px;
    }
}