.hero-section {
    margin: 0;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #303A2F;

    .overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 1;
        left: 0;
        right: 0;
        top: 0;

        figure {
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        video {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        .video-fullscreen {
            position: relative;
            overflow: hidden;
            width: 100vw;
            height: 100vh;

            iframe {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100vw;
                height: 100vh;
                transform: translate(-50%, -50%);

                @media (min-aspect-ratio: 16/9) {
                    height: 56.25vw;
                }

                @media (max-aspect-ratio: 16/9) {
                    width: 177.78vh;
                }
            }
        }
    }

    .container {
        max-width: var(--site-width);

        .content {
            position: relative;
            z-index: 5;
            color: #fff;
            /* max-width: 1050px; */
            width: 100%;
            text-align: center;
            margin: 120px 0 60px;

            h1 {
                /* text-transform: uppercase; */
                font-size: 2.5em;
                color: #fff;
                margin: 0 0 20px;
            }

            p {
                font-size: 16px;
                /* text-transform: uppercase; */
            }

            .hero-line {
                width: 2px;
                height: 40px;
                margin: 30px auto 40px;
                background-color: #7c8f77;
            }

            .hero-buttons {
                text-align: center;

                .button {
                    background-color: #fff;
                    color: #000;
                    border: #fff 1px solid;
                    margin: 4px;

                    &:hover {
                        background-color: #F5F3ED;
                    }

                    &.alt {
                        background-color: transparent;
                        color: #fff;
                        border: #fff 1px solid;

                        &:hover {
                            background-color: #fff;
                            color: #000;
                        }
                    }
                }
            }
        }
    }
}

@media (--viewport-md-min) {
    .hero-section {

        .container {

            .content {
                margin: 220px 0 30px;

                h1 {
                    font-size: 3.2em;
                }
            }
        }
    }
}

@media (--viewport-lg-min) {
    .hero-section {

        .container {

            .content {
                margin: 220px 0 40px;

                h1 {
                    font-size: 4em;
                }
            }
        }
    }
}