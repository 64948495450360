.button-inline {
    padding: 8px 16px;
    width: auto;
    display: inline-block;
    text-decoration: none;
    font-size: inherit;
    text-align: center;
    transition: .2s;
    background-color: #000;
    color: #fff;
    border: solid 1px #000;

    &:hover {
        background-color: #fff;
        color: #000;
        transition: .2s;
    }
}