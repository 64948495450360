/* Input validation */
input.valid {
    border-bottom: 2px solid #4dff2d;
}

input.input-validation-error {
    border-bottom: 2px solid #ff2d2d;
}

input[type='checkbox'].valid + .form-input-type-checkbox__checkmark {
    border: 2px solid #4dff2d;
}

input[type='checkbox'].input-validation-error + .form-input-type-checkbox__checkmark {
    border: 2px solid #ff2d2d;
}

input[type='radio'].valid + .form-input-type-checkbox__checkmark {
    border: 2px solid #4dff2d;
}

input[type='radio'].input-validation-error + .form-input-type-checkbox__checkmark {
    border: 2px solid #ff2d2d;
}