input:focus,
select:focus,
textarea:focus,
button:focus {
    /* outline-color: #000;
    outline-style: solid;
    outline-offset: 2px; */
    outline: none;
}

.form__container {
    /* padding: 0 30px; */
}