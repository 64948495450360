.heading-3 {
    /* text-transform: uppercase; */
    margin: 0 0 10px;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 1.5px;

    &--black {
        color: #000;
    }

    &--white {
        color: #fff;
    }
}

@media (--viewport-sm-min) {
    .heading-3 {
        margin: 0 0 20px;
        font-size: 22px;
    }
}

@media (--viewport-md-min) {
    .heading-3 {
        font-size: 24px;
        letter-spacing: 2px;
    }
}

@media (--viewport-lg-min) {
    .heading-3 {
        font-size: 28px;
    }
}