/* Insert css here */
.rooster-select-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;

    .rooster-select {
        width: 100%;
    }
}

@media (--viewport-md-min) {
    .rooster-select-wrap {
        flex-direction: row;
        gap: 20px;
    }
}