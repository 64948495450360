.form-input-type-checkbox {
    width: 100%;
    margin-bottom: 20px;
}

.form-input-type-checkbox label {
    width: 100%;
    display: block;
    font-family: var(--heading-font-family);
    color: #000;
}

.form-input-type-checkbox input {
    width: 100%;
    border: 1px #000 solid;
    background-color: transparent;
    font-family: var(--base-font-family);
    font-size: 16px;
    letter-spacing: 1.5px;
    line-height: 20px;
    padding: 8px 0;
}

@media (--viewport-sm-min) {
    .form-input-type-checkbox {
        margin-bottom: 20px;
    }

    .form-input-type-checkbox label {
        font-size: 20px;
    }

    .form-input-type-checkbox input {
        border: 2px #000 solid;
        font-size: 20px;
        letter-spacing: 2px;
        line-height: 30px;
    }
}

.form-input-type-checkbox__container {
    display: block;
    position: relative;
    padding-left: 22px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.form-input-type-checkbox__container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.form-input-type-checkbox__checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: transparent;
    border: solid #000 2px;
}

.form-input-type-checkbox__container:hover input ~ .form-input-type-checkbox__checkmark {
    background-color: rgba(0, 0, 0, 0.198);
}

.form-input-type-checkbox__container input:checked ~ .form-input-type-checkbox__checkmark {
    background-color: transparent;
}

.form-input-type-checkbox__checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.form-input-type-checkbox__container input:checked ~ .form-input-type-checkbox__checkmark:after {
    display: block;
}

.form-input-type-checkbox__container .form-input-type-checkbox__checkmark:after {
    left: 4px;
    top: 0px;
    width: 5px;
    height: 10px;
    border: solid #000;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

@media (--viewport-sm-min) {
    .form-input-type-checkbox__container {
        padding-left: 30px;
    }

    .form-input-type-checkbox__checkmark {
        top: 5px;
        height: 20px;
        width: 20px;
    }

    .form-input-type-checkbox__container .form-input-type-checkbox__checkmark:after {
        left: 5px;
        top: 0px;
        width: 7px;
        height: 13px;
    }
}