.tarieven-switch {

    .ts-title {
        margin-bottom: 24px;
    }

    .ts-clubs {
        display: flex;
        justify-content: space-between;

        .ts-club {
            position: relative;
            width: 48%;
            border-radius: 16px;
            overflow: hidden;
            background-color: #000;

            &:hover {

                img {
                    transform: scale(1);
                }

                .ts-club-name {
                    font-size: 20px;
                }
            }

            img {
                opacity: 0.8;
                transform: scale(1.05);
                transition: .3s;
                transform-origin: center;
            }

            .ts-club-name {
                position: absolute;
                z-index: 2;
                width: 100%;
                text-align: center;
                top: 50%;
                transition: .3s;
                transform: translateY(-50%);
                color: #fff;
                font-weight: 300;
                font-size: 20px;
            }
        }
    }
}

@media (--viewport-sm-min) {
    .tarieven-switch {

        .ts-clubs {

            .ts-club {
                border-radius: 22px;


                &:hover .ts-club-name {
                    font-size: 29px;
                }

                .ts-club-name {
                    font-size: 28px;
                }
            }
        }
    }
}

@media (--viewport-md-min) {
    .tarieven-switch {

        .ts-clubs {

            .ts-club {
                border-radius: 32px;

                &:hover .ts-club-name {
                    font-size: 37px;
                }

                .ts-club-name {
                    font-size: 36px;
                }
            }
        }
    }
}
