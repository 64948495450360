.cta-banner {
    background-color: #f5f3ed;
}

.cta-banner__container {
    padding: 30px 0 40px;
    max-width: 1000px;
    text-align: center;
    margin: 0 auto;
}

@media (--viewport-sm-min) {
    .cta-banner__container {
        padding: 60px 0 70px;
        max-width: 630px;
    }
}

@media (--viewport-md-min) {
    .cta-banner__container {
        padding: 80px 0 90px;
        max-width: 830px;
    }
}

@media (--viewport-lg-min) {
    .cta-banner__container {
        padding: 100px 0 110px;
        max-width: 1000px;
    }
}