.footer__line{
    width: 80%;
    height: 1px;
    background-color: black;
    margin: 16px 10% 16px;
}

.footer__title {
    text-align: center;
    margin: 0 0 20px;
}

.footer__list {
    text-align: center;
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer__list li {
    margin-bottom: 20px;
}

.footer__list li a {
    color: #000;
}

.footer__social {
    text-align: center;
    list-style: none;
    padding: 0;
    margin: 20px 0 40px;
}

.footer__social li {
    display: inline-block;
    margin: 0 4px;
}

.footer__social li a{
    display: inline-block;
    font-size: 18px;
    width: 40px;
    height: 40px;
    line-height: 38px;
    border-radius: 100%;
    color: #fff;
    background-color: #000;
    border-width: 1px;
    border-style: solid;
    border-color: black;
    transition: 0.2s;

    & i {
        line-height: 38px;
        width: 38px;
    }
}

.footer__social li a:hover{
    color: #000;
    background-color: #fff;
    transition: 0.2s;
}

@media (--viewport-md-min) {
    .footer__title {
        margin: 0 0 20px;
    }

    .footer__social {
        margin: 40px 0 0;
    }
}