.banner-2__background {
    /* height: 280px; */
    height: 75%;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    position: absolute;
    background-color: #f5f3ed;
}

.banner-2__container {
    padding-top: 100px;
    width: 100%;
    margin-bottom: 40px;
}

.banner-2__heading {
    font-size: 28px;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
    text-align: center;
    color: #000000;
    margin: 0 auto 20px;
}

.banner-2__image img {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

@media (--viewport-sm-min) {
    .banner-2__background {
        /* height: 560px; */
    }

    .banner-2__container {
        padding-top: 120px;
        margin-bottom: 100px;
    }

    .banner-2__heading {
        font-size: 60px;
        letter-spacing: 2px;
        max-width: 900px;
    }

    .banner-2__image img {
        width: 100%;
        height: 500px;
        object-fit: cover;
    }
}