.button {
    padding: 16px 12px 16px;
    width: auto;
    display: inline-block;
    text-decoration: none;
    font-family: var(--heading-font-family);
    /* text-transform: uppercase; */
    font-size: 16px;
    text-align: center;
    transition: .2s;
    border-radius: 6px;

    &--default,
    &--green {
        color: #fff;
        background-color: #7c8f77;

        &:hover {
            color: #fff;
            background-color: #6b7a67;
        }
    }

    &--blue {
        color: #fff;
        background-color: #a8d6ed;

        &:hover {
            background-color: #97c7df;
        }
    }

    &--black {
        color: #fff;
        background-color: #000000;

        &:hover {
            background-color: #414141;
        }
    }


    &--white {
        color: #000;
        background-color: #fff;

        &:hover {
            background-color: #F5F3ED;
        }
    }

    &--white-border {
        color: #000;
        background-color: #fff;
        border: 1px #000 solid;

        &:hover {
            background-color: #F5F3ED;
            border: 1px #000 solid;
        }
    }

    &--white-hero {
        color: #000;
        background-color: #fff;

        &:hover {
            background-color: #6b7a67;
            color: #fff;
        }
    }

    &:hover {
        transition: .2s;
    }
}

@media (--viewport-ms-max) {
    .button {
        width: 100%;
    }
}

@media (--viewport-sm-min) {
    .button {
        padding: 16px 26px 16px;
        font-size: 16px;
        width: fit-content;
        margin: 0 auto;

        &--white-hero {
            margin: 0;
        }
    }
}

@media (--viewport-md-min) {
    .button {
        font-size: 16px;
    }
}

@media (--viewport-lg-min) {
    .button {
        /* font-size: 20px; */
    }
}