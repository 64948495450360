.active-header-scroll {
    .header-bar {
        opacity: 1;
    }
}

.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    transition: top 0.4s;
    z-index: 10;
}

.navbar__top {
    background: #fff;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);

}

.navbar__top a {
    padding: 8px 0;
    font-size: 16px;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
    display: inline-block;
    color: #000;
}

.navbar__right{
    text-align: right;
}

.navbar__bottom {
    background: transparent;
}

.navbar__bottom a {
    padding: 20px 0;
    display: inline-block;
    color: #fff;
    font-size: 22px;
}

.navbar__bottom div.navbar__right>a {
    padding: 20px 0;
}

.header-logo {
    position: relative;
    height: 36px;
}

.logo-beeld {
    position: absolute;
}

.logo-beeld svg {
    height: 36px;
    fill: #fff;
}

.logo-club svg {
    height: 36px;
    fill: #fff;
}

/* .logo-locatie {
    position: absolute;
    left: 42px;
    top: -6px;
    font-family: var(--heading-font-family);
    font-weight: 700;
    color: #000;
    font-size: 32px;
    display: inline-block;
    opacity: 1;
    overflow: hidden;
    transition: opacity .6s ease .0s, top 0s ease 0s;
} */

.navbar__bottom-hamburger {
    background: #303a2f;
    padding: 12px 11px 9px;
    /* font-size: 20px; */
    letter-spacing: 0;
    line-height: 0;
    cursor: pointer;
    border-radius: 5px;
}

.header-hamburger {
    position: absolute;
    top: 20px;
    right: 0;

    .header-hamburger-wrap {
        background: #303a2f;
        border-radius: 5px;
        padding: 12px 11px 7px;
        cursor: pointer;

        span {
            display: block;
            background-color: #fff;
            height: 2px;
            width: 15px;
            margin-bottom: 4px;
        }

    }
}

.navbar__bottom-hamburger a i {
    margin-top: 8px;
    font-size: 18px;
}

.navbar__bottom-cta-button {
    position: absolute;
    display: none;
    right: 58px;
    top: 21px;

    a {
        margin-left: 8px;
        padding: 15px 20px 14px;
        font-weight: 300;
        font-size: 14px;
        line-height: 14px;
        border-radius: 6px;
        font-family: var(--heading-font-family);
        /* text-transform: uppercase; */
        background-color: transparent;
        border: 1px solid #fff;
        color: #fff;

        &:hover {
            background-color: #F5F3ED;
            border: 1px solid #F5F3ED;
            color: #000;
        }

        &.alt {
            background-color: #fff;
            color: #000;

            &:hover {
                background-color: #F5F3ED;
                border: 1px solid #F5F3ED;
                color: #000;
            }
        }
    }
}

.separator-line-verticale {
    width: 1px;
    height: 12px;
    margin: 0 8px;
    background-color: #000;
    display: inline-block;
}

.info-text-link-cursive.active {
    border-bottom: 2px solid #000;
}

@media (--viewport-sm-min) {
    .separator-line-verticale {
        margin: 0 12px;
    }

    .logo-club {
        display: inline-block;
        overflow: hidden;
        position: absolute;
        opacity: 1;
        left: 0;
        top: 0;
        transition: opacity .6s ease .0s, top 0s ease 0s;
    }

    .logo-club svg {
        height: 36px;
    }

    /* .logo-locatie {
        left: 210px;
        top: -6px;
        opacity: 1;
    } */
}

.logo-fade {
    opacity: 0;
    top: -150px;
    transition: opacity .6s ease 0s, top 0s ease .7s;
}

/* menuoverlay */
.closebtn-navbar {
    text-decoration: none;
    color: #000000;

    .cn-wrap {
        position: relative;
        width: 41px;
        height: 41px;
        transform: rotate(45deg);

        .cn-1 {
            position: absolute;
            background-color: #000;
            width: 21px;
            height: 3px;
            top: 19px;
            left: 10px;
        }

        .cn-2 {
            position: absolute;
            background-color: #000;
            width: 3px;
            height: 21px;
            top: 10px;
            left: 19px;
        }
    }
}

.header-bar {
    position: fixed;
    z-index: 7;
    top: 0;
    left: 0;
    width: 100%;
    height: 78px;
    background-color: #7c8f77;
    opacity: 0;
    transition: .2s;
}

.closebtn-navbar:hover, .closebtn-navbar:focus {
    color: #000000;
}

.closebtn-navbar {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 32px;
    z-index: 10;
}

@media screen and (max-height: 450px) {
    .closebtn-navbar {
        /* font-size: 40px; */
        top: 15px;
        right: 35px;
    }
}

@media (--viewport-sm-min) {
    .closebtn-navbar {
        position: absolute;
        top: 25px;
        right: 35px;
        /* font-size: 32px; */
        z-index: 10;
    }
}

.halfoverlaymenu__back {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,.58);
    z-index: 100;
    transition: left 0s 0s, opacity .3s ease-in;
}

.halfoverlaymenu--active .halfoverlaymenu__back--active{
    opacity: 0;
    left: 100%;
    transition: left 0s .4s, opacity .3s .1s ease-in;
}

.halfoverlaymenu__front {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 10%;
    background-color: #fff;
    z-index: 100;
    transition: top 0s, left .25s ease-in;
    overflow-y: scroll;
}

.halfoverlaymenu--active .halfoverlaymenu__front--active{
    left: 100%;
    top: 100%;
    transition: top 0s .2s, left .2s ease-in;
}

.halfoverlaymenu__front__list {
    position: relative;
    min-height: 100%;
    padding: 60px 40px 80px;

    .halfoverlay-cta-buttons {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;

        a {
            color: #fff;
            width: 100%;
        }

        .halfoverlay-cta-button {
            font-family: var(--heading-font-family);
            display: block;
            text-align: center;
            padding: 24px 18px;
            font-size: 16px;
            height: 100%;
            background-color: #7C8F77;

            &:hover {
                background-color: #6b7a67;
            }

            &.alt {
                background-color: #303A2F;

                &:hover {
                    background-color: #242c23;
                }
            }
        }
    }

    & .main-links {
        margin: 20px 0;

        & ul {
            list-style: none;
            padding: 0;

            & li {
                transition: .2s;

                &:hover {
                    /* margin-left: 5px; */
                    transform: translateX(5px);
                    transition: .2s;
                }

                & a {

                    & span {
                        font-family: var(--heading-font-family);
                        font-weight: 300;
                        font-size: 28px;
                        line-height: 1.3;
                        color: #000;
                    }
                }
            }
        }
    }

    & .club-links {
        margin: 20px 0;

        & ul {
            list-style: none;
            padding: 0;

            & li {
                transition: .15s;

                &:hover {
                    margin-left: 6px;
                    transition: .15s;
                }

                & a {

                    & span {
                        /* font-family: var(--heading-font-family); */
                        /* text-transform: uppercase; */
                        font-size: 19px;
                        color: #000;
                    }
                }
            }
        }
    }

    & .social-links {
        margin: 20px 0;

        & ul {
            list-style: none;
            padding: 0;

            & li {
                display: inline-block;
                margin-right: 8px;

                & a {
                    text-align: center;
                    display: inline-block;
                    font-size: 18px;
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    border-radius: 100%;
                    color: #fff;
                    background-color: #000;
                    border-width: 1px;
                    border-style: solid;
                    border-color: black;
                    transition: 0.2s;

                    &:hover {
                        color: #000;
                        background-color: #fff;
                        transition: 0.2s;
                    }

                    & i {
                        line-height: 38px;
                        width: 38px;
                    }
                }
            }
        }
    }
}

@media (--viewport-sm-max) {
    .navbar__mobile-fix {
        display: none !important;
    }
}

@media (--viewport-sm-min) {
    .halfoverlaymenu__front {
        left: 30%;
    }

    .halfoverlaymenu__front__list {
        padding: 120px 80px;

        & .main-links {
            margin: 30px 0;

            & ul {

                & li {

                    &:hover {
                        /* margin-left: 10px; */
                        transform: translateX(10px);
                    }

                    & a {

                        & span {
                            font-size: 36px;
                        }
                    }
                }
            }
        }

        & .club-links {
            margin: 30px 0;

            & ul {

                & li {

                    &:hover {
                        margin-left: 6px;
                    }

                    & a {

                        & span {
                            font-size: 24px;
                        }
                    }
                }
            }
        }

        & .social-links {
            margin: 30px 0;
        }
    }
}

@media (--viewport-md-min) {
    .header-bar {
        height: 88px;
    }

    .navbar__bottom a {
        padding: 26px 0;
    }

    .navbar__bottom-cta-button a {
        padding: 15px 20px 14px;
    }


    .header-hamburger {
        top: 21px;

        .header-hamburger-wrap {
            padding: 16px 14px 11px;

            span {
                height: 2px;
                width: 17px;
                margin-bottom: 4px;
            }

        }
    }

    .header-logo {
        transform: translateY(-4px);
    }

    .logo-beeld svg {
        height: 46px;
    }

    .logo-club svg {
        height: 46px;
    }

    .navbar__bottom-hamburger {
        padding: 14px 15px 11px;
        font-size: 20px;
        border-radius: 6px;
    }

    .navbar__bottom-cta-button {
        display: block;
    }

    .halfoverlaymenu__front {
        left: 40%;
    }

    .halfoverlaymenu__front__list {
        padding: 120px 80px;

        & .main-links {
            margin: 30px 0;

            & ul {

                & li {

                    &:hover {

                    }

                    & a {

                        & span {
                            font-size: 40px;
                        }
                    }
                }
            }
        }
    }
}

@media (--viewport-lg-min) {
    .halfoverlaymenu__front {
        left: 50%;
    }
}

@media (--viewport-xl-min) {
    .halfoverlaymenu__front {
        left: 60%;
    }
}