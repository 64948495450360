.heading-1 {
    /* text-transform: uppercase; */
    margin: 0 0 20px;
    font-size: 28px;
    letter-spacing: 1.5px;

    &--black {
        color: #000;
    }

    &--white {
        color: #fff;
    }
}

@media (--viewport-sm-min) {
    .heading-1 {
        margin: 0 0 30px;
        font-size: 42px;
    }
}

@media (--viewport-md-min) {
    .heading-1 {
        font-size: 60px;
        letter-spacing: 2px;
    }
}

@media (--viewport-lg-min) {
    .heading-1 {
        /* font-size: 80px; */
    }
}