.rooster-les-block__container {
    padding: 15px;
    overflow: hidden;
    margin-bottom: 10px;
    cursor: pointer;
    background-color: #7C8F77;
}

.rooster-les-block__content {

}

.rooster-les-block__time {
    width: 70px;
    float: left;
}

.rooster-les-block__time span {
    font-family: var(--heading-font-family);
    font-size: 18px;
    color: #fff;
    font-weight: 700;
}

.rooster-les-block__les {
    float: left;
    width: calc(100% - 70px);
}

.rooster-les-block__les h3 {
    font-size: 16px;
    /* text-transform: uppercase; */
    letter-spacing: 1.5px;
    color: #fff;
    margin: 0;
}

.rooster-les-block__les p {
    font-size: 16px;
    letter-spacing: 1.5px;
    color: #fff;
    margin: 0;
}

@media (--viewport-sm-min) {
    .rooster-les-block__container {
        padding: 25px;
        margin-bottom: 20px;
        transition: .2s;

        &:hover {
            margin-left: 20px;
            margin-right: -20px;
            transition: .2s;
        }
    }

    .rooster-les-block__content {

    }

    .rooster-les-block__time {
        width: 130px;
    }

    .rooster-les-block__time span {
        font-size: 36px;
    }

    .rooster-les-block__les {
        width: calc(100% - 130px);
    }

    .rooster-les-block__les h3 {
        font-size: 26px;
        letter-spacing: 2px;
    }

    .rooster-les-block__les p {
        font-size: 20px;
        letter-spacing: 2px;
    }
}