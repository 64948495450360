.popup-actie {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;

    &.active {
        display: block;
    }

    &.no-form {

        .popup-actie-container {

            .popup-actie-wrap {
                max-width: 700px;

                .content {
                    width: 100%;
                }

                .form {
                    display: none;
                }
            }
        }
    }

    &.no-content {

        .popup-actie-container {

            .popup-actie-wrap {
                max-width: 600px;

                .content {
                    display: none;
                }

                .form {
                    width: 100%;

                    form {
                        width: 100%;
                    }
                }
            }
        }
    }

    .club-select {
        min-width: unset
    }

    .popup-actie-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        .popup-actie-bg {
            background-color: #000;
            opacity: 0.5;
            position: fixed;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .popup-actie-wrap {
            position: relative;
            z-index: 2;
            width: 320px;
            max-height: 100%;
            overflow: auto;

            .popup-actie-scroll {
                position: relative;
                margin: 32px 0;
                background-color: #fff;
                border-radius: 22px;
                overflow: hidden;
            }

            .popup-actie-close {
                position: absolute;
                z-index: 3;
                top: 10px;
                right: 10px;
                width: 36px;
                height: 36px;
                background-color: transparent;
                background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='far' data-icon='times' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' class='svg-inline--fa fa-times fa-w-10 fa-xs'%3E%3Cpath fill='#000000' d='M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z' class=''%3E%3C/path%3E%3C/svg%3E");
                background-size: 18px 25px;
                background-repeat: no-repeat;
                background-position: center;
                cursor: pointer;
            }

            .content {
                position: relative;
                padding: 25px;
                background-color: #000;

                .text {
                    position: relative;
                    z-index: 2;
                    color: #fff;

                    .subtitle {
                        font-weight: 300;
                        /* text-transform: uppercase; */
                        color: var(--color-pink);
                        font-size: 1.2em;
                    }

                    h2 {
                        font-size: 1.8em;
                        margin: 26px 0 36px;
                        color: #fff;
                    }

                    p {
                        color: #fff;
                    }

                    ul {
                        padding-left: 24px;
                        color: #fff;
                    }

                    .button {

                    }
                }

                .image {
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0.3;

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }
            }

            .form {
                display: flex;
                padding: 25px;
                align-items: center;
                background-color: #F5F3ED;

                .umbraco-forms-form {
                    width: 100%;
                }

                label {
                    font-size: 15px;
                }

                input {
                    border: none;
                    background-color: #fff;
                    font-size: 16px;
                    color: #000;
                    padding: 7px 10px;
                }

                .form-input-type-select select {
                    border: none;
                    background-color: #fff;
                    color: #000;
                    padding: 7px 10px;
                    font-size: 16px;
                    background-image: linear-gradient(45deg,transparent 50%,#000 0),linear-gradient(135deg,#000 50%,transparent 0),linear-gradient(90deg,transparent,transparent);
                }

                textarea {
                    background-color: #fff;
                    color: #000;
                    font-size: 16px;
                    padding: 7px 10px;
                    border: none;
                    width: 100%;
                    max-width: 100%;
                }

                .form-input-type-submit input {
                    background-color: #303a2f;
                    color: #fff;
                    border-radius: 8px;
                    padding: 12px 8px 10px;
                }

                small {
                    display: block;
                    margin: 16px 0 6px 0;
                    font-size: 12px;
                    line-height: 13px;
                    letter-spacing: 0px;
                }
            }
        }
    }
}

@media(--viewport-sm-min) {
    .popup-actie {

        .popup-actie-container {

            .popup-actie-wrap {
                width: 600px;

                .popup-actie-scroll {

                }

                .content {
                    padding: 45px;

                    .text {

                        h2 {
                            font-size: 2.1em;
                        }
                    }
                }

                .form {
                    padding: 45px;
                }
            }
        }
    }
}

@media(--viewport-md-min) {
    .popup-actie {

        .popup-actie-container {

            .popup-actie-wrap {
                width: 900px;

                .popup-actie-scroll {
                    display: flex;
                }

                .content {
                    width: 57%;
                    padding: 70px 45px;

                    .text {

                        h2 {
                            font-size: 2.4em;
                        }
                    }
                }

                .form {
                    width: 43%;
                }
            }
        }
    }
}
