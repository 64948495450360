/**
 * Base
 */
 @media (--viewport-sm-min) {
    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        background: #f7f7f7;
    }

    ::-webkit-scrollbar-thumb {
        background: #c8c8c8;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #bbbbbb;
    }
}

:root {
    --site-background-color: var(--site-background-color, #fff);
}

/* Box sizing */
* {
    box-sizing: border-box;

    &::before,
    &::after {
        box-sizing: border-box;
    }
}

/* Body */
body {
    background-color: var(--site-background-color);
    font-family: var(--base-font-family);
    font-weight: var(--base-font-weight);
    font-size: var(--base-font-size);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--color-text);
    line-height: 1.4;
    overflow-x: hidden;
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;

    @media (--viewport-lg-min) {
        font-size: 18px;
    }
}

/* Section */
section {
    display: flow-root;
}

/* Links */
a {
    color: var(--color-ocean, #2e4b9b);
    text-decoration: none;
    /* text-decoration-skip-ink: auto;*/
    display: inline;
    &:hover,
    &:focus {
        color: shade(var(--color-ocean, #2e4b9b), 10%);
    }
}

/* Horizontal ruler */
hr {
    border: none;
    border-bottom: 1px solid var(--color-gray);
    margin: 30px 0;

    &.white{
        border-bottom: 2px solid var(--color-white);
    }
}

/* Image handling */
img {
    width: 100%;
    height: auto;
    display: block;
}

figure {
    margin: 0;
    background-size: auto 100%;
    background-position: center center;
    background-repeat: no-repeat;

    &img {
        max-width: 100%;
        width: 100%;
        height: auto;
        display: block;
        margin: 0 auto;
        transition: opacity 0.2s;
    }

    &.contain-width img {
        width: auto;
    }
}

video {
    background-size: cover;
    display: table-cell;
    vertical-align: middle;
    width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--heading-font-family);
    font-weight: 300;
    line-height: 1.2;
    letter-spacing: 1.5px;
    color: var(--color-heading);
}

h2 {
    margin: 0 0 15px;
    font-size: 28px;
}

h3 {
    margin: 0 0 15px;
    font-size: 20px;
    font-weight: 400;
}

body,
p {
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: 1.5px;
}

ul,
ol {
    padding: 0 0 0 20px;
}

@media (--viewport-sm-min) {
    h2 {
        margin: 0 0 20px;
        font-size: 32px;
    }

    h3 {
        margin: 0 0 20px;
        font-size: 22px;
    }

    body,
    p {
        font-size: 16px;
    }
}

@media (--viewport-md-min) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        letter-spacing: 2px;
    }

    h2 {
        font-size: 40px;
    }

    h3 {
        font-size: 24px;
    }

    body,
    p {
        font-size: 18px;
        letter-spacing: 2px;
    }
}

@media (--viewport-lg-min) {
    h2 {
        font-size: 46px;
    }

    h3 {
        font-size: 28px;
    }
}

.font-16 p {
    font-size: 16px;
}

.subscription-actie {
    text-align: center;
    margin-bottom: 24px;

    .subscription-actie-wrap {
        display: inline-block;
        background-color: #4a5d63;
        color: #fff;
        padding: 10px 22px;
        border-radius: 5px;

        .actie-label {
            font-weight: 700;
            /* font-style: italic; */
            font-family: var(--heading-font-family);
            /* font-style: italic; */
            /* text-transform: uppercase; */
        }
    }
}

.intro-margin {
    margin: 0;
    padding: 40px 0;
}

@media (--viewport-sm-min) {
    .intro-margin {
        padding: 60px 0;
    }
}

@media (--viewport-md-min) {
    .intro-margin {
        padding: 80px 0;
    }
}

@media (--viewport-lg-min) {
    .intro-margin {
        padding: 100px 0;
    }
}