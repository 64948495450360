.rooster-modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.rooster-modal__container {
    background-color: #fff;
    /* width: calc(100% - 30px); */
    width: 100%;
    max-width: 500px;
    margin: 0 15px;
    max-height: 100vh;
    overflow-y: auto;
    box-sizing: border-box;
}

.rooster-modal__close {
    position: absolute;
    top: 20px;
    right: 20px;
    background: transparent;
    border: 0;
    z-index: 2;
    color: #fff;
}

.rooster-modal__close i {
    font-size: 26px;
    cursor: pointer;
}

.rooster-modal-img__container {
    position: relative;
}

.rooster-modal-img__image {
    display: block;
    width: 100%;
    height: 260px;
    object-fit: cover;
}

.rooster-modal-img__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 1;
    transition: .5s ease;
    background-color: rgba(0, 0, 0, 0.2);
}

.rooster-modal-img__content {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 30px;
}

.rooster-modal-img__content h3 {
    color: white;
    font-size: 26px;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
    margin: 30px 0 30px;
    text-align: center;
}

.rooster-modal-img__trainer {
    width: 100%;
    overflow: hidden;
}

.rooster-modal-img__trainer-image {
    width: 60px;
    float: left;
}

.rooster-modal-img__trainer-image img {
    border-radius: 100%;
}

.rooster-modal-img__trainer-content {
    float: left;
    width: calc(100% - 60px);
    padding: 10px 15px;
}

.rooster-modal-img__trainer-content span,
.rooster-modal-img__trainer-content h4 {
    font-family: var(--heading-font-family);
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 1.5px;
    color: #fff;
    margin: 0;
}

.rooster-modal-content__container {
    padding: 30px;
}

.rooster-modal-content__datum {
    font-size: 16px;
    letter-spacing: 1.5px;
    margin: 0 0 5px;
    /* text-transform: uppercase; */
}

.rooster-modal-content__tijd {
    display: block;
    font-family: var(--heading-font-family);
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 1.5px;
    margin: 0 0 5px;
    /* text-transform: uppercase; */
}

.rooster-modal-content__locatie {
    font-size: 16px;
    letter-spacing: 1.5px;
    margin: 0 0 10px;
}

@media (--viewport-sm-min) {
    .rooster-modal__container {
        width: 500px;
    }

    .rooster-modal__close {
        top: 20px;
        right: 20px;
    }

    .rooster-modal__close i {
        font-size: 32px;
    }

    .rooster-modal-img__image {
        height: 360px;
    }

    .rooster-modal-img__content {
        padding: 40px;
    }

    .rooster-modal-img__content h3 {
        font-size: 32px;
        letter-spacing: 2px;
        margin: 60px 0 60px;
    }

    .rooster-modal-img__trainer {
    }

    .rooster-modal-img__trainer-image {
        width: 80px;
    }

    .rooster-modal-img__trainer-image img {
    }

    .rooster-modal-img__trainer-content {
        width: calc(100% - 80px);
        padding: 10px 20px;
    }

    .rooster-modal-img__trainer-content span,
    .rooster-modal-img__trainer-content h4 {
        font-size: 20px;
        letter-spacing: 2px;
    }

    .rooster-modal-content__container {
        padding: 40px;
    }

    .rooster-modal-content__datum {
        font-size: 20px;
        letter-spacing: 2px;
    }

    .rooster-modal-content__tijd {
        font-size: 28px;
        letter-spacing: 2px;
    }

    .rooster-modal-content__locatie {
        font-size: 20px;
        letter-spacing: 2px;
        margin: 0 0 20px;
    }
}

  /**************************\
    Demo Animation Style
  \**************************/
  @keyframes mmfadeIn {
      from { opacity: 0; }
        to { opacity: 1; }
  }

  @keyframes mmfadeOut {
      from { opacity: 1; }
        to { opacity: 0; }
  }

  @keyframes mmslideIn {
    from { transform: translateY(15%); }
      to { transform: translateY(0); }
  }

  @keyframes mmslideOut {
      from { transform: translateY(0); }
      to { transform: translateY(-10%); }
  }

  .micromodal-slide {
    display: none;
  }

  .micromodal-slide.is-open {
    display: block;
  }

  .micromodal-slide[aria-hidden="false"] .rooster-modal__overlay {
    animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
  }

  .micromodal-slide[aria-hidden="false"] .rooster-modal__container {
    animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
  }

  .micromodal-slide[aria-hidden="true"] .rooster-modal__overlay {
    animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
  }

  .micromodal-slide[aria-hidden="true"] .rooster-modal__container {
    animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
  }

  .micromodal-slide .rooster-modal__container,
  .micromodal-slide .rooster-modal__overlay {
    will-change: transform;
  }
