.banner-breadcrumbs {
    padding: 90px 0 16px;

    h1 {
        text-align: left;
    }
}

.breadcrumbs {
    font-size: 13px;
    margin-bottom: 6px;

    a {
        color: #000;
        text-decoration: none;

    }

    .breadcrumb_last {

    }
}

@media (--viewport-sm-min) {
    .banner-breadcrumbs {
        padding: 120px 0 16px;
    }
}

@media (--viewport-md-min) {
    .banner-breadcrumbs {
        padding: 140px 0 16px;
    }
}