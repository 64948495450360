.review-slider-section {
    overflow: hidden;
    background-color: var(--color-green);
    margin: 0;
    padding: 40px 0;

    .header {
        margin-bottom: 1em;

        h2 {

        }

    }

    .review-carousel {
        width: calc(100vw - var(--half-gutter-width) );

        .carousel-cell {
            width: 94%;
            padding-right: 12px;

            .content {
                transition: .3s;

                .content-wrap {
                    position: relative;
                    max-width: unset;

                    .bubble {
                        position: relative;
                        background-color: #fff;
                        padding: 26px 26px 20px;
                        margin-bottom: 48px;

                        &::after {
                            position: absolute;
                            bottom: -36px;
                            left: 20px;
                            content: '';
                            width: 0;
                            height: 0;
                            border-bottom: 36px solid #ff000000;
                            border-left: 24px solid #fff;
                        }

                        h2 {
                            font-size: 22px;
                            margin-bottom: 12px;
                        }

                        .review-p {
                            font-size: .9em;
                            margin: 12px 0 12px;
                        }
                    }

                    .credits {

                        .review-rating {
                            margin-top: 24px;
                            color: yellow;
                        }

                        .review-name {
                            font-weight: 600;
                            /* text-transform: uppercase; */
                            /* font-style: italic; */
                            /* font-family: var(--heading-font-family); */
                        }
                    }
                }
            }
        }
    }
}

@media (--viewport-sm-min) {
    .review-slider-section {
        padding: 60px 0;

        .header {
            /* padding-left: 5%; */
        }

        .review-carousel {
            /* padding-left: 5%; */
            width: calc(100vw - var(--half-gutter-width) );

            .carousel-cell {
                width: 56%;

                .content {

                    .content-wrap {
                        padding-right: 40px;

                        .bubble {

                            h2 {
                                font-size: 24px;
                            }

                            .review-p {
                                /* font-size: 1em; */
                            }
                        }

                        .credits {

                            .review-rating {
                                margin-top: 32px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (--viewport-md-min) {
    .review-slider-section {
        padding: 90px 0;

        .header {
            margin-bottom: 1.5em;

            h2 {

            }

        }

        .review-carousel {
            width: 100vw;

            .carousel-cell {
                width: 50%;

                .content {

                    .content-wrap {
                        padding-right: 80px;

                        .bubble {

                            h2 {
                                font-size: 20px;
                            }

                            .review-p {
                                /* font-size: 1em; */
                            }
                        }

                        .credits {
                        }
                    }
                }
            }
        }
    }
}

@media (--viewport-lg-min) {
    .review-slider-section {
        padding: 90px 0 110px;

        .header {

            h2 {

            }

        }

        .review-carousel {
            width: calc(100vw - ((100vw - var(--site-width) + var(--gutter-width)) / 2));

            .carousel-cell {
                width: calc((var(--site-width) - var(--gutter-width)) / 2);
            }
        }
    }
}