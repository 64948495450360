.info-text {
    margin: 0 0 25px;
    font-size: 16px;
    letter-spacing: 1.5px;

    &--black {
        color: #000;
    }

    &--white {
        color: #fff;
    }
}

@media (--viewport-sm-min) {
    .info-text {
        margin: 0 0 30px;
        font-size: 16px;
    }
}

@media (--viewport-md-min) {
    .info-text {
        font-size: 18px;
        letter-spacing: 2px;
    }
}

/* @media (--viewport-lg-min) {
    .info-text {
        font-size: 20px;
    }
} */