.masonry__text {
    color: #000;
    padding: 25px;
    text-align: center;
}

.masonry__title {
    font-weight: 400;
    /* text-transform: uppercase; */
    font-size: 20px;
    margin: 0 0 12px;
}

.masonry__subtitle {
    color: #000;
    font-size: 16px;
}

@media (--viewport-ms-max) {
    .masonry__image-wrap {
        margin-left: -20px;
        margin-right: -20px;

        & img {
            width: 100%;
            max-height: 350px;
            object-fit: cover;
        }
    }

    .masonry__overlay {
        position: relative;
        z-index: 3;
        margin-top: -60px;
        margin-bottom: 20px;
    }
}

@media (--viewport-sm-min) {
    .masonry__container {
        position: relative;
        /* margin-bottom: 20px; */
        height: 100%;
    }
    
    .small__gutter>div.masonry__container{
        margin-bottom: 15px;
    }
    
    .masonry__image-wrap {
        width: 100%;
        height: 100%;
    }
    
    .masonry__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    .masonry__overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: .4s ease;
        background-color: #d6bcc8;
    }
    
    .masonry__container:hover .masonry__overlay {
        opacity: 1;
    }
    
    .masonry__text {
        font-size: 20px;
        position: absolute;
        padding: 20px;
        width: 100%;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
    }
    
    .masonry__title {
        color: #000;
        font-size: 22px;
        margin-bottom: 12px;
    }
    
    .masonry__subtitle {
        color: #000;
        margin: 0;
        font-size: 16px;
    }

    .masonry__button-fix {
        display: none;
    }
}

@media (--viewport-md-min) {
    .masonry__title {
        
    }
    
    .masonry__subtitle {
        font-size: 18px;
    }
}

@media (--viewport-lg-min) {
    .masonry__title {
        font-size: 28px;
    }
    
    .masonry__subtitle {
        font-size: 20px;
    }
}