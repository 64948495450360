/* .overzicht-tile__container {
    margin-bottom: 30px;
}

.overzicht-tile-heading {
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 1.5px;
    margin-top: 10px;
}

@media (--viewport-sm-min) {
    .overzicht-tile__container {
        margin-bottom: 20px;
    }

    .overzicht-tile__container img {
        transition: .2s;
    }

    .overzicht-tile__container:hover img {
        margin-top: -20px;
        transition: .2s;
    }

    .overzicht-tile__container .overzicht-tile-heading {
        transition: .2s;
    }

    .overzicht-tile__container:hover .overzicht-tile-heading {
        margin-top: 40px;
        transition: .2s;
    }

    .overzicht-tile-heading {
        font-size: 19px;
        letter-spacing: 2px;
        margin: 20px 0 20px;
    }
}

@media (--viewport-md-min) {
    .overzicht-tile-heading {
        font-size: 22px;
    }
} */

.overzicht-section {

    .content-wrap {
        max-width: 1100px;
    }
}

.overzicht-tile__container {
    margin-bottom: 20px;
    background-color: #f5f3ed;
    overflow: hidden;
    border-radius: 4px;
    height: 100%;

    a {
        text-decoration: inherit;
        color: inherit;
        display: block;
        height: 100%;

        &:hover .overzicht-tile__wrap .otw-image img {
            transform: scale(1.04);
        }

        &:hover .overzicht-tile__wrap .otw-content h3 {
            /* transform: translateY(-40px); */
            opacity: 0;
        }

        &:hover .overzicht-tile__wrap .hover-message {
            opacity: 1;
        }
    }

    .overzicht-tile__wrap {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;

        .otw-image {
            position: relative;
            overflow: hidden;

            img {
                aspect-ratio: 1;
                object-fit: cover;
                transition: .4s;
            }

            .otw-tag-wrap {
                position: absolute;
                right: 0;
                top: 0;
                padding: 6px;
                font-size: 11px;
                color: #000;

                .otw-tag {
                    position: relative;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 -960 960 960' width='24px' fill='%23000000'%3E%3Cscript xmlns=''/%3E%3Cpath d='M480-480q33 0 56.5-23.5T560-560q0-33-23.5-56.5T480-640q-33 0-56.5 23.5T400-560q0 33 23.5 56.5T480-480Zm0 294q122-112 181-203.5T720-552q0-109-69.5-178.5T480-800q-101 0-170.5 69.5T240-552q0 71 59 162.5T480-186Zm0 106Q319-217 239.5-334.5T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 100-79.5 217.5T480-80Zm0-480Z'/%3E%3Cscript xmlns=''/%3E%3C/svg%3E");
                    background-size: 14px;
                    background-position: 5px center;
                    background-repeat: no-repeat;
                    display: inline-block;
                    padding: 4px 8px 4px 20px;
                    border-radius: 50px;
                    background-color: #fff;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    letter-spacing: 0.7px;
                }
            }
        }

        .otw-content {
            position: relative;
            flex: 1;
            align-content: center;

            h3 {
                text-align: center;
                position: relative;
                z-index: 1;
                transition: .3s ease-in;
                background-color: #f5f3ed;
                padding: 16px 5px;
                font-size: 16px;
                margin: 0;
            }

            .hover-message {
                position: absolute;
                display: flex;
                align-items: center;
                text-align: center;
                justify-content: center;
                z-index: 2;
                width: 100%;
                height: 100%;
                left: 0;
                bottom: 0;
                transition: .3s ease-in;
                padding: 12px 12px;
                background-color: #303a2f;
                font-family: var(--heading-font-family);
                color: #fff;
                opacity: 0;
            }
        }
    }
}


@media (--viewport-md-min) {
    .overzicht-tile__container {
        border-radius: 6px;

        .overzicht-tile__wrap {

            .otw-image {

                .otw-tag-wrap {
                    padding: 16px;
                    font-size: 12px;

                    .otw-tag {
                        /* padding: 4px 8px; */
                        background-size: 16px;
                        padding: 4px 8px 4px 25px;
                    }
                }
            }

            .otw-content {

                h3 {
                    padding: 20px;
                    font-size: 18px;
                }

                .hover-arrow {
                    padding: 17px 20px;
                }
            }
        }
    }
}
