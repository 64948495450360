.voorwaarden-text {
    margin: 0 0 10px;
    font-size: 16px;
    letter-spacing: 1.5px;
    opacity: .5;

    &--black {
        color: #000;
    }

    &--white {
        color: #fff;
    }
}

@media (--viewport-sm-min) {
    .voorwaarden-text {
        margin: 0 0 15px;
        font-size: 16px;
    }
}