.image-text {
    margin: 40px 0;

    .image-text-wrap {

        .text {
            margin-bottom: 50px;
        }
    }
}

@media (--viewport-sm-min) {
    .image-text {
        margin: 60px 0;
    }
}

@media (--viewport-md-min) {
    .image-text {
        margin: 80px 0;

        &.reverse .image-text-wrap {
            flex-direction: row-reverse;
        }

        .image-text-wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .text {
                width: 48%;
                margin: 0;
            }

            .image {
                width: 48%;
            }
        }
    }
}

@media (--viewport-lg-min) {
    .image-text {
        margin: 100px 0;
    }
}
