/**
 * Fonts
 */

 /* @import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:400i,700i|Roboto:300&display=swap'); */

@font-face {
    font-family: "ITC";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(/dist/fonts/ITCAvantGardeGothicLTBold.woff2) format("woff2")
}