.inschrijven-pagina__container {
    padding: 20px;
    background-color: #F5F3ED;

    .heading-2 {
        font-size: 24px;
    }

    /* & #clubSelectWrap, */
    & #ageSelectWrap,
    & #abboSelectWrap,
    & #upgradeSelectWrap,
    & #contactSelectWrap {
        visibility: visible;
        height: auto;

        &.hiddenTrue {
            visibility: hidden;
            height: 0;
            margin: 0;
        }
    }

    & .wrap {
        margin-bottom: 30px;

        & h3.ip-header {
            color: #000;
            font-size: 1.3em;
        }
    }

    /* Abbo actie label */
    & .abbo-actie {
        margin-bottom: 24px;
        color: #000;
        display: inline-block;
        background-color: #A8D6ED;
        padding: 6px 12px;
        border-radius: 5px;

        & .actie-label {
            font-family: var(--heading-font-family);
            /* font-style: italic; */
            /* text-transform: uppercase; */
            font-weight: 700;
            line-height: 1.2;
            letter-spacing: 1px;
            font-size: 16px;
            letter-spacing: 1.5px;
            margin-right: 12px;
        }
    }

    /* leeftijd */
    .custom-inschrijf-select--ipleeftijd {
        border: none;
        width: 100%;
        padding: 0;
        font-size: 20px;
        border-radius: 0px;
        overflow: hidden;
        margin-bottom: 12px;
        background: #fff url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
        background-repeat: no-repeat, repeat;
        background-position: right .7em top 50%, 0 0;
        background-size: .65em auto, 100%;

        & select {
            cursor: pointer;
            padding: 14px 12px;
            /* background-color: #99b6b1; */
            color: #000;
            width: 100%;
            border: none;
            box-shadow: none;
            background: transparent;
            /* font-family: var(--base-font-family); */
            background-image: none;
            -webkit-appearance: none;

            & option {
                color: #000;
            }
        }
    }

    /* 3 blokken naast elkaar (abbonement) */
    & .abbo-wrap {

        & .abbo-container {
            position: relative;
            margin-bottom: 20px;
            margin-top: 30px;

            & input[type=radio] {
                width: auto;
                box-sizing: border-box;
                padding: 0;
                position: absolute;
                z-index: -1;
                opacity: 0;
                bottom: 0;
                left: 50%;

                &:checked + .abbo-label {
                    border: 2px #000 solid;
                }
            }

            & .abbo-label {
                display: block;
                color: #000;
                text-align: center;
                /* height: 100%; */

                & .abo-actie-label {
                    /* position: absolute; */
                    /* bottom: 0; */
                    /* left: 0; */
                    /* transform: translate(-0, 50%); */
                    width: 100%;
                    display: block;

                    & .inner-wrap {
                        font-family: var(--heading-font-family);
                        /* font-style: italic; */
                        /* text-transform: uppercase; */
                        font-weight: 700;
                        line-height: 1.2;
                        letter-spacing: 1px;
                        display: inline-block;
                        text-align: center;
                        background-color: #A8D6ED;
                        color: #000;
                        padding: 6px 12px;
                        /* border-radius: 5px; */
                        font-size: 15px;
                        /* min-width: 120px; */
                        width: 100%;
                    }
                }

                & .abbo-content {
                    position: relative;
                    background-color: #fff;
                    padding: 40px 20px;
                    cursor: pointer;
                    border: 2px #fff solid;
                    /* height: 100%; */

                    & .top-label {
                        position: absolute;
                        top: 0;
                        left: 0;
                        transform: translate(-0, -50%);
                        width: 100%;
                        display: block;

                        & .inner-wrap {
                            font-family: var(--heading-font-family);
                            /* font-style: italic; */
                            /* text-transform: uppercase; */
                            font-weight: 700;
                            line-height: 1.2;
                            letter-spacing: 1px;
                            display: inline-block;
                            text-align: center;
                            background-color: #7C8F77;
                            color: #fff;
                            padding: 6px 12px;
                            border-radius: 5px;
                            font-size: 15px;
                        }
                    }

                    & img {
                        width: 100%;
                        max-width: 84px;
                    }

                    & span {
                        display: block;
                        color: #000;

                        &.weken {
                            font-family: var(--heading-font-family);
                            font-weight: 600;
                            /* font-size: 1.2em; */
                            font-size: 32px;
                            text-align: left;
                            margin-bottom: 4px;
                            /* text-transform: uppercase; */
                            /* color: var(--color-blue-light); */
                        }

                        &.abonnement {
                            /* text-transform: uppercase; */
                            font-size: 16px;
                            letter-spacing: 1px;
                            font-weight: 400;
                            text-align: left;
                            margin: 0 0 16px;
                            color: #000;
                        }

                        /* &.kosten {
                            margin: 16px 0 0;
                            color: #000;
                        } */

                        &.prijs {
                            text-align: left;

                            & .prijs-title {
                                display: block;
                                font-size: 28px;
                            }

                            & .prijs-sub {
                                display: block;
                                font-size: 16px;
                            }
                        }
                    }

                    .checked-list {
                        list-style-type: none;
                        margin: 16px 0 16px;
                        padding: 0;
                        text-align: left;

                        & li {
                            position: relative;
                            padding-left: 24px;
                            margin-bottom: 6px;
                            font-size: 15px;

                            &::before {
                                content: '';
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 28px;
                                height: 100%;
                                background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='check' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%237C8F77' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z' class=''%3E%3C/path%3E%3C/svg%3E");
                                background-size: 24px 14px;
                                background-position: -3px 4px;
                                background-repeat: no-repeat;
                            }
                        }
                    }

                    &--meest-gekozen {
                        position: relative;
                        overflow: hidden;

                        & .corner-ribbon {
                            font-family: var(--heading-font-family);
                            width: 200px;
                            background-color: #7C8F77;
                            position: absolute;
                            top: 25px;
                            left: -50px;
                            text-align: center;
                            font-size: 17px;
                            line-height: 42px;
                            /* letter-spacing: 1px; */
                            color: #fff;
                            transform: rotate(-45deg);
                            -webkit-transform: rotate(-45deg);
                            box-shadow: 0 0 3px rgba(0,0,0,.3);
                        }
                    }
                }
            }
        }
    }

    /* upgrades heeft eigen css */

    /* contactgegevens */
    .contactgegevens-wrap {

        #submit-registration {
            display: flex;
            align-items: center;

            &::after {
                content: '';
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 -960 960 960' width='24px' fill='%23000'%3E%3Cpath d='M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-155.5t86-127Q252-817 325-848.5T480-880q17 0 28.5 11.5T520-840q0 17-11.5 28.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160q133 0 226.5-93.5T800-480q0-17 11.5-28.5T840-520q17 0 28.5 11.5T880-480q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80Z'/%3E%3C/svg%3E");
                display: none;
                /* display: inline-block; */
                width: 30px;
                height: 30px;
                background-size: 30px;
                transform-origin: center;
                animation: rotation 1.1s infinite linear reverse;
            }

            &.loading {
                pointer-events: none;

                &::after {
                    display: inline-block;
                }

                input {
                    background-color: #686868;
                }
            }
        }

        input {
            width: 100%;
            padding: 8px;
            border: none;
            border-radius: 0px;
            resize: vertical;
            margin-bottom: 12px;
            background-color: #fff;
            font-family: var(--base-font-family);
            margin-right: 12px;

            &::placeholder {
                color: #7b7b7b;
            }

            &[type=submit] {
                position: relative;
                border-radius: 6px;
                margin-top: 16px;
                padding: 18px 14px 12px;
                border: none;
                background-color: #303A2F;
                color: #fff;
                cursor: pointer;
                transition: all .2s ease-in-out;

                &:hover {
                    background-color: #000;
                    color: #fff;
                    transition: all .2s ease-in-out;
                }
            }

            & ~ i {
                display: block;
                margin: -12px 0 20px;
                color: red;
            }
        }

        label {
            color: #000;
            font-size: 18px;
            margin: 6px 0;
            display: inline-block;
        }

        .custom-checkbox {
            position: relative;
            display: block;
            min-height: 1.5rem;
            /* padding-left: 1.5rem; */

            &:first-child label {
                margin: 10px 0 3px;
            }

            /* &:last-child label {
                margin: 3px 0 20px;
            } */

            & input[type=checkbox] {
                width: auto;
                box-sizing: border-box;
                padding: 0;
                position: absolute;
                z-index: -1;
                opacity: 0;

                &:checked {

                    & ~ label {

                        &::before {
                            box-shadow: 0 0 0 0 transparent;
                            color: var(--color-input-border);
                            border-color: var(--color-input-border);
                            background-color: #fff;
                        }

                        &::after {
                            display: block;
                        }
                    }
                }
            }

            & label {
                cursor: pointer;
                position: relative;
                vertical-align: top;
                padding-left: 1.5rem;

                &::before {
                    background-color: #dee2e6;

                    /* border-radius: .3125rem; */

                    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

                    pointer-events: none;
                    background-color: #fff;
                    border: 1px solid var(--color-input-border);

                    position: absolute;
                    top: .25rem;
                    /* left: -1.5rem; */
                    left: 0;
                    display: block;
                    width: 1rem;
                    height: 1rem;
                    content: "";
                }

                &::after {
                    content: "";
                    position: absolute;
                    display: none;

                    top: .35rem;
                    /* left: -1.1rem; */
                    left: 0.35rem;
                    width: 5px;
                    height: 10px;
                    border: solid #000;
                    border-width: 0 2px 2px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }
        }

        .custom-radio {
            position: relative;
            display: block;
            min-height: 1.5rem;
            /* padding-left: 1.5rem; */

            &:first-child label {
                margin: 10px 0 3px;
            }

            &:last-child label {
                margin: 3px 0 20px;
            }

            & input[type=radio] {
                width: auto;
                box-sizing: border-box;
                padding: 0;
                position: absolute;
                z-index: -1;
                opacity: 0;

                &:checked {

                    & ~ label {

                        &::before {
                            box-shadow: 0 0 0 0 transparent;
                            color: #000;
                            border-color: var(--color-input-border);
                            background-color: #fff;
                        }

                        &::after {
                            display: block;
                        }
                    }
                }
            }

            & label {
                cursor: pointer;
                position: relative;
                vertical-align: top;
                margin: 3px 0;
                padding-left: 1.5rem;

                &::before {
                    background-color: #dee2e6;
                    border-radius: 100%;
                    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                    pointer-events: none;
                    background-color: #fff;
                    border: 1px solid var(--color-input-border);
                    position: absolute;
                    top: .25rem;
                    /* left: -1.5rem; */
                    left: 0;
                    display: block;
                    width: 18px;
                    height: 18px;
                    content: "";
                }

                &::after {
                    content: "";
                    position: absolute;
                    display: none;
                    top: calc(.25rem + 6px);
                    /* left: calc(-1.5rem + 6px); */
                    left: 6px;
                    width: 6px;
                    height: 6px;
                    background-color: #000;
                    border-radius: 100%;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }
        }

        .custom-select {
            border: none;
            width: 100%;
            padding: 0;
            border-radius: 0px;
            overflow: hidden;
            margin-bottom: 12px;
            background: #fff url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
            background-repeat: no-repeat, repeat;
            background-position: right .7em top 50%, 0 0;
            background-size: .65em auto, 100%;

            & select {
                cursor: pointer;
                padding: 8px 8px;
                background-color: #fff;
                width: 100%;
                border: none;
                box-shadow: none;
                background: transparent;
                font-family: var(--base-font-family);
                background-image: none;
                -webkit-appearance: none;
            }
        }

        textarea {
            width: 100%;
            padding: 8px;
            border: none;
            border-radius: 0px;
            resize: vertical;
            margin-bottom: 12px;
            background-color: #fff;
            font-family: var(--base-font-family);

            &::placeholder {
                color: #7b7b7b;
            }
        }
    }

    /* Samengesteld abbo */
    .abbo-voorbeeld {
        background-color: #fff;
        padding: 20px;
        margin-bottom: 30px;

        & .title {
            display: block;
            color: #000;
            font-family: var(--heading-font-family);
            font-size: 20px;
            margin-bottom: 8px;
        }

        & .subtitle {
            display: block;
            font-family: var(--heading-font-family);
            font-size: 20px;
            color: #000;
            padding: 8px;
            background-color: #A8D6ED;
            margin-bottom: 8px;

            & .price {
                display: block;
                margin-top: 4px;
                font-family: var(--base-font-family);
                font-size: 18px;
                font-weight: 400;
                letter-spacing: 1.5px;
            }
        }
    }

    .signature-input {
        position: relative;
        margin: 0 0 22px;

        .signature-input-wrap {
            position: relative;

            .signature-pad {
                /* border: 1px solid #021a2b; */
                background-color: #ffffff;
            }

            .signature-input-title {
                position: absolute;
                top: 12px;
                left: 12px;
                color: gray;
                font-size: 14px;
                pointer-events: none;
            }
        }

        .signature-clear {
            display: inline-block;
            padding: 6px 14px;
            background-color: #303A2F;
            color: #fff;
            border-radius: 3px;
            cursor: pointer;
        }

        .signature-hidden-input textarea {
            position: absolute;
            opacity: 0;
            height: 1px;
            width: 1px;
            padding: 0;
            margin: 0;
            top: 36px;
            left: 62px;
        }
    }
}

@media (--viewport-sm-min) {
    .inschrijven-pagina__container {
        padding: 40px;

        .heading-2 {
            font-size: 28px;
        }

        & .custom-inschrijf-select {

            & select {
                font-size: 20px;
                font-family: var(--base-font-family);
                font-weight: 400;
                letter-spacing: 1.5px;
            }
        }

        & .abbo-wrap {
            display: flex;
            margin: 0 -10px;

            & .abbo-container {
                width: calc(100% / 3);
                padding: 0 10px;
                margin-bottom: 0;
            }
        }
    }
}


@media (--viewport-md-min) {
    .inschrijven-pagina__container {
        padding: 40px;

        .heading-2 {
            font-size: 36px;
        }

        & .abbo-wrap {

            & .abbo-container {
                /* padding: 0 20px; */
                margin-top: 16px;
            }
        }


        /* contactgegevens */
        .contactgegevens-wrap {
            input {
                margin-bottom: 14px;

                &[type=submit] {
                    width: auto;
                    padding: 18px 30px 13px;
                }
            }

            .custom-select {
                margin-bottom: 14px;
            }
        }
    }
}