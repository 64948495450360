.form-input-type-text {
    width: 100%;
    margin-bottom: 20px;
}

.form-input-type-text label {
    width: 100%;
    display: block;
    font-family: var(--heading-font-family);
    color: #000;
}

.form-input-type-text input {
    width: 100%;
    border: 1px #000 solid;
    color: #000;
    background-color: transparent;
    font-family: var(--base-font-family);
    font-size: 16px;
    letter-spacing: 1.5px;
    line-height: 20px;
    padding: 8px 12px;
}

@media (--viewport-sm-min) {
    .form-input-type-text {
        margin-bottom: 20px;
    }

    .form-input-type-text label {
        font-size: 20px;
    }

    .form-input-type-text input {
        border: 2px #000 solid;
        font-size: 20px;
        letter-spacing: 2px;
        line-height: 30px;
    }
}