.info-text-link-cursive {
    position: relative;

    &--default {
        color: #2e4b9b;
    }

    &--black {
        color: #000;
    }

    &--white {
        color: #fff;
    }

    & span {
        display: inline-block;
    }

    &:before {
        /* content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: -2px;
        left: 0;
        background: #000;
        visibility: hidden;
        transform: scaleX(0);
        transition: .2s linear; */
    }
    
    &:hover:before,
    &:focus:before {
        /* visibility: visible;
        transform: scaleX(1); */
    }
}

.navbar__top {

    & .info-text-link-cursive {

        &:before {
            /* bottom: 0 */
        }
    }
}

.halfoverlaymenu__items {

    & .info-text-link-cursive {

        &:before {
            /* height: 2px; */
            /* bottom: -2px; */
        }
    }

}

@media (--viewport-sm-min) {
    .info-text-link-cursive {
        /* font-size: 20px; */
        /* letter-spacing: 2px; */
        
        &:before {
            /* height: 2px; */
            /* bottom: -2px; */
        }
    }

    .halfoverlaymenu__items {

        & .info-text-link-cursive {
    
            &:before {
                /* height: 3px; */
                /* bottom: -3px; */
            }
        }
    
    }
}