.form-input-type-select {
    width: 100%;
    margin-bottom: 20px;

    & label {
        width: 100%;
        display: block;
        font-family: var(--heading-font-family);
        color: #000;
    }

    & select {
        width: 100%;
        background-color: transparent;
        border: 1px #000 solid;
        display: inline-block;
        color: #000;
        font-family: var(--base-font-family);
        font-size: 16px;
        letter-spacing: 1.5px;
        line-height: 20px;
        padding: 8px 12px;

        /* reset */
        margin: 0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none;

        /* arrow */
        background-image:
        linear-gradient(45deg, transparent 50%, white 50%),
        linear-gradient(135deg, white 50%, transparent 50%),
        linear-gradient(to right, transparent, transparent);
        background-position:
        calc(100% - 10px) calc(1em + 2px),
        calc(100% - 5px) calc(1em + 2px),
        100% 0;
        background-size:
        5px 5px,
        5px 5px,
        2.5em 2.5em;
        background-repeat: no-repeat;

        & option {
            color: #000;
        }
    }
}

@media (--viewport-sm-min) {
    .form-input-type-select {
        margin-bottom: 20px;

        & label {
            font-size: 20px;
        }

        & select {
            border: 2px #000 solid;
            font-size: 20px;
            letter-spacing: 2px;
            line-height: 30px;
        }
    }
}
