.cta-block-2__text {
    color: #000;
    font-size: 16px;
    padding: 25px;
    text-align: center;
    background-color: #f5f3ed;

    & ul {
        text-align: left;
    }
}

@media (--viewport-ms-max) {
    .cta-block-2__image-wrap {
        margin-left: -20px;
        margin-right: -20px;
    }

    .cta-block-2__overlay {
        position: relative;
        z-index: 3;
        margin-top: -60px;
        margin-bottom: 20px;
    }
}

@media (--viewport-sm-min) {
    .cta-block-2__container {
        position: relative;
        margin-bottom: 20px;
        height: 100%;
    }

    .small__gutter>div.cta-block-2__container{
        margin-bottom: 15px;
    }

    .cta-block-2__image-wrap {
        width: 100%;
    }

    .cta-block-2__image {
        display: block;
        height: auto;
    }

    .cta-block-2__overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 1;
        transition: .4s ease;
        /* background-color: #d6bcc8; */
    }

    .cta-block-2__container:hover .cta-block-2__overlay {
        /* opacity: 1; */
    }

    .cta-block-2__text {
        font-size: 20px;
        position: absolute;
        padding: 30px;
        width: 50%;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
    }

    .cta-block-2__button-fix .button {
        width: 100%;
    }
}