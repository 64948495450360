.lidmaatschapskeuze-checkbox {
    width: 100%;
    /* background-color: rgba(255, 255, 255, .2); */
    background-color: #fff;
    /* padding: 15px; */
    margin-bottom: 14px;

    /* &--light {
        background-color: #99b6b1;
    }

    &--dark {
        background-color: #4a5d63;
    } */

    &:last-child {
        margin-bottom: 30px;
    }

    & label {
        width: 100%;
        display: block;
        /* padding: 15px; */
        padding: 10px 10px 10px 30px;
        font-family: var(--heading-font-family);
    }

    & input {
        width: 100%;
        border: none;
        border-bottom: 1px #000 solid;
        background-color: transparent;
        font-family: var(--base-font-family);
        font-size: 16px;
        letter-spacing: 1.5px;
        line-height: 20px;
        padding: 8px 0;;
    }

    &--lidmaatschap {
        color: #000;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 1.5px;
        margin: 0;

        /* & span {
            font-family: var(--base-font-family);
            font-size: 16px;
        } */
    }

    &--prijs {
        color: #000;
        font-size: 16px;
        letter-spacing: 1.5px;
        font-family: var(--base-font-family);
        font-weight: 400;
        margin: 0;

        /* & span {
            font-family: var(--base-font-family);
            font-size: 16px;
            font-weight: 400;
        } */
    }
}

@media (--viewport-sm-min) {
    .lidmaatschapskeuze-checkbox {
        /* margin-bottom: 20px; */

        & label {
            font-size: 20px;
        }

        & input {
            border-bottom: 2px #000 solid;
            font-size: 20px;
            letter-spacing: 2px;
            line-height: 30px;
        }

        &--lidmaatschap {
            font-size: 20px;
            letter-spacing: 2px;

            /* & span {
                font-size: 20px;
            } */
        }

        &--prijs {
            font-size: 20px;
            letter-spacing: 2px;
            text-align: right;

            /* & span {
                font-size: 20px;
                font-weight: 400;
            } */
        }
    }
}

.lidmaatschapskeuze-checkbox__container {
    display: block;
    position: relative;
    padding-left: 22px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.lidmaatschapskeuze-checkbox__container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.lidmaatschapskeuze-checkbox__checkmark {
    position: absolute;
    top: 23px;
    left: 7px;
    height: 16px;
    width: 16px;
    background-color: transparent;
    border: solid #000 2px;
}

.lidmaatschapskeuze-checkbox__container:hover input ~ .lidmaatschapskeuze-checkbox__checkmark {
    /* background-color: rgba(0, 0, 0, 0.198); */
    background-color: #F5F3ED;
}

.lidmaatschapskeuze-checkbox__container input:checked ~ .lidmaatschapskeuze-checkbox__checkmark {
    background-color: transparent;
}

.lidmaatschapskeuze-checkbox__checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.lidmaatschapskeuze-checkbox__container input:checked ~ .lidmaatschapskeuze-checkbox__checkmark:after {
    display: block;
}

.lidmaatschapskeuze-checkbox__container .lidmaatschapskeuze-checkbox__checkmark:after {
    left: 4px;
    top: 0px;
    width: 5px;
    height: 10px;
    border: solid #000;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

@media (--viewport-sm-min) {
    .lidmaatschapskeuze-checkbox label.lidmaatschapskeuze-checkbox__container {
        padding-left: 30px;
        padding: 15px 25px 15px 45px;
    }

    .lidmaatschapskeuze-checkbox__checkmark {
        top: 18px;
        left: 15px;
        height: 20px;
        width: 20px;
    }

    .lidmaatschapskeuze-checkbox__container .lidmaatschapskeuze-checkbox__checkmark:after {
        left: 5px;
        top: 1px;
        width: 6px;
        height: 12px;
        border-width: 0 3px 3px 0;
    }
}