.index-club-keuze {
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.index-club-oisterwijk__container,
.index-club-vught__container,
.index-club-waalwijk__container {
    height: 100vh;
    width: 100%;
    background: #000;
    transition: 1s ease;
}

.index-club__container {
    position: relative;
    width: 100%;
    height: 100%;;

    & img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

.index-club__image {
    display: block;
    width: 100%;
    height: auto;
}

.index-club__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 1;
    transition: .5s ease;
    background-color: rgba(0, 0, 0, 0.5);
}

.index-club__content {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

.index-club__logo {
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0 auto 20px;
    transition: width .2s ease 0s, height .2s ease 0s, opacity .3s ease .3s;
}

.index-club__club {
    transform: rotate(-90deg);
    margin: 0;
    min-width: 160px;
    color: white;
    /* text-transform: uppercase; */
    letter-spacing: 2px;
}

.index-club__club h2 {
    font-size: 28px;
}

.index-club__button {
    width: 0;
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: width .2s ease 0s, height .2s ease 0s, opacity .3s ease .3s;
}

.index-hover-active {
    width: 300%;

    & .index-club__overlay {
        background-color: rgba(0, 0, 0, 0.2);
    }

    & .index-club__logo {
        width: 60px;
        height: auto;
        opacity: 1;
        transition: width .2s ease 0s, height .2s ease 0s, opacity .3s ease .3s;
    }

    & .index-club__club {
        transform: rotate(0deg);
        transition: .4s ease;
    }

    & .index-club__button {
        width: 100%;
        height: auto;
        opacity: 1;
        transition: width .2s ease 0s, height .2s ease 0s, opacity .3s ease .3s;
    }
}

.index-hover-passive {
    width: 100%;
    transition: 1s ease;

    & .index-club__overlay {
        background-color: rgba(0, 0, 0, 0.7);
    }

    & .index-club__logo {
        width: 0;
        height: 0;
        opacity: 0;
        transition: width .2s ease .3s, height .2s ease .3s, opacity .3s ease 0s;
    }

    & .index-club__club {
        transform: rotate(-90deg);
        transition: .4s ease;
    }

    & .index-club__button {
        width: 0;
        height: 0;
        opacity: 0;
        transition: width .2s ease .3s, height .2s ease .3s, opacity .3s ease 0s;
    }
}

@media (--viewport-sm-min) {
    .index-club__club {
        transform: rotate(0deg);
    }

    .index-club__club h2 {
        font-size: 36px;
    }

    .index-hover-active {
        & .index-club__logo {
            width: 80px;
        }

        & .index-club__club {
            transform: rotate(0deg);
            transition: .4s ease;
        }

        & .index-club__club h2 {
            font-size: 46px;
            transition: .5s;
        }
    }

    .index-hover-pasive {
        & .index-club__club {
            transform: rotate(-90deg);
            transition: .4s ease;
        }

        & .index-club__club h2 {
            font-size: 36px;
            transition: .5s;
        }
    }
}

@media (--viewport-md-min) {
    .index-club__club h2 {
        font-size: 46px;
    }

    .index-hover-active {
        & .index-club__logo {
            width: 100px;
        }

        & .index-club__club h2 {
            font-size: 60px;
            transition: .5s;
        }
    }

    .index-hover-pasive {

        & .index-club__club h2 {
            font-size: 46px;
            transition: .5s;
        }
    }
}

@media (--viewport-lg-min) {
    .index-club__content {
        min-width: 250px;
    }

    .index-club__club h2 {
        font-size: 60px;
        transition: .5s;
    }

    .index-hover-active {
        & .index-club__logo {
            width: 100px;
        }

        & .index-club__club h2 {
            font-size: 90px;
            transition: .5s;
        }
    }

    .index-hover-pasive {

        & .index-club__club h2 {
            font-size: 60px;
            transition: .5s;
        }
    }
}