/* Internet Explorer 9, 10, 11 */
@media screen and (min-width:0\0){
    #site {
        overflow-x: hidden !important;
    }

    .hero-video__container {
        overflow: hidden;
    }

    .hero-video__video-wrap {
        background-color: #000;
    }

    .hero-video__video {
        width: 100%;
        height: auto;
        z-index: -1;
    }

    .masonry-tile-overzicht__grid {
        grid-template-areas:
            'masonry-item1 masonry-item2 masonry-item3'
            'masonry-item4 masonry-item5 masonry-item6';
        grid-template-rows: auto auto;

        & .masonry__container {
            margin: 0 10px;
        }
    }

    .flickity-slider {
        width: 100%;
    }

    .carousel-image {
        width: auto;
    }
    
    .banner-2__image img {
        height: auto;
    }

}

/* Egde */
@supports (-ms-ime-align: auto) {
    #site {
        overflow-x: hidden !important;
    }

    .hero-video__container {
        overflow: hidden;
    }

    .hero-video__video-wrap {
        background-color: #000;
    }

    .hero-video__video {
        width: 100%;
        height: auto;
        z-index: -1;
    }
}