.cta-block__text {
    color: #000;
    /* font-size: 14px; */
    padding: 25px;
    text-align: center;

    & ul {
        text-align: left;
    }
}

@media (--viewport-ms-max) {
    .cta-block__image-wrap {
        margin-left: -20px;
        margin-right: -20px;
    }

    .cta-block__overlay {
        position: relative;
        z-index: 3;
        margin-top: -60px;
        margin-bottom: 20px;
    }
}

@media (--viewport-sm-min) {
    .cta-block__container {
        position: relative;
        margin-bottom: 20px;
        height: 100%;
    }

    .small__gutter>div.cta-block__container{
        margin-bottom: 15px;
    }

    .cta-block__image-wrap {
        width: 100%;
    }

    .cta-block__image {
        display: block;
        height: auto;
    }

    .cta-block__overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 1;
        transition: .4s ease;
    }

    .cta-block__text {
        /* font-size: 20px; */
        position: absolute;
        padding: 30px;
        width: 54%;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
        background-color: #f5f3ed;
    }

    .cta-block__text .button {
        width: 100%;
    }
}

@media (--viewport-md-min) {
    .cta-block__text {
        width: 46%;
    }
}

@media (--viewport-lg-min) {
    .cta-block__text {
        width: 38%;
    }
}