/**************************\
  Basic Modal Styles
\**************************/

.modal {

}

.form-modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.form-modal__container {
    background-color: #7C8F77;
    padding: 30px;
    height: 100vh;
    width: 100vw;
    /* max-width: 500px; */
    /* max-height: 100vh; */
    /* border-radius: 4px; */
    overflow-y: auto;
    box-sizing: border-box;
}

.form-modal__close {
    position: fixed;
    top: 20px;
    right: 20px;
    background: transparent;
    border: 0;
}

.form-modal__close i {
    font-size: 26px;
    color: #fff;
    cursor: pointer;
}

.form-modal-content__container {
    max-width: 500px;
    /* max-height: 100vh; */
    margin: 60px auto;
}

@media (--viewport-sm-min) {
    .form-modal__close {
        top: 30px;
        right: 30px;
    }

    .form-modal__close i {
        font-size: 32px;
    }

    .form-modal-content__container {
        margin: 100px auto;
    }
}

  /**************************\
    Demo Animation Style
  \**************************/
  @keyframes mmfadeIn {
      from { opacity: 0; }
        to { opacity: 1; }
  }

  @keyframes mmfadeOut {
      from { opacity: 1; }
        to { opacity: 0; }
  }

  @keyframes mmslideIn {
    from { transform: translateY(15%); }
      to { transform: translateY(0); }
  }

  @keyframes mmslideOut {
      from { transform: translateY(0); }
      to { transform: translateY(-10%); }
  }

  .micromodal-slide {
    display: none;
  }

  .micromodal-slide.is-open {
    display: block;
  }

  .micromodal-slide[aria-hidden="false"] .form-modal__overlay {
    animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
  }

  .micromodal-slide[aria-hidden="false"] .form-modal__container {
    animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
  }

  .micromodal-slide[aria-hidden="true"] .form-modal__overlay {
    animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
  }

  .micromodal-slide[aria-hidden="true"] .form-modal__container {
    animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
  }

  .micromodal-slide .form-modal__container,
  .micromodal-slide .form-modal__overlay {
    will-change: transform;
  }
