.form-input-type-submit {
    width: 100%;
    margin-bottom: 20px;
    /* background-color: #000; */
    overflow: hidden;
}

.form-input-type-submit label {
    width: 100%;
    display: block;
    font-family: var(--heading-font-family);
}

.form-input-type-submit input {
    color: #fff;
    background-color: #000;
    width: 100%;
    border: none;
    font-family: var(--base-font-family);
    font-size: 16px;
    /* text-transform: uppercase; */
    letter-spacing: 1.5px;
    line-height: 20px;
    padding: 8px 0;
    cursor: pointer;
    transition: .2s;

    &:hover {
        background-color: #1f1f1f;
        color: #fff;
        transition: .2s;
    }
}

@media (--viewport-sm-min) {
    .form-input-type-submit {
        margin-bottom: 20px;
    }

    .form-input-type-submit label {
        font-size: 20px;
    }

    .form-input-type-submit input {
        font-size: 20px;
        letter-spacing: 2px;
        line-height: 30px;
        font-size: 16px;
    }
}