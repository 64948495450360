.hero-video__section {
    margin: 0px;
}

.hero-video__container {
    height: 100vh;
    position: relative;
}

.hero-video__video-wrap {
    height: 100%;
    width: 100%;
    position: absolute;
}

.hero-video__video {
    height: 100%;
    object-fit: cover;
}

.hero-video__overlay {
    height: 100%;
    width: 100%;
    position: absolute;
}

.hero-video__content {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;

    & .hero-buttons {

        & .button {
            margin-bottom: 12px;
            padding: 8px 24px;
            width: auto;
        }
    }
}

.hero-video__content img {
    width: 80px;
    margin: 0 auto 20px;
}

.hero-video__content h1 {
    /* text-transform: uppercase; */
    margin: 0 0 20px;
    font-size: 28px;
    letter-spacing: 1.5px;
    color: #fff;
}

/* scroll down */
.hero-video__scroll {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    bottom: 70px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-video__scroll-container {
    position: relative;
    width: 24px;
    height: 24px;
}

.hero-video__scroll-chevron {
    position: absolute;
    width: 28px;
    height: 8px;
    opacity: 0;
    transform: scale3d(0.5, 0.5, 0.5);
    animation: move 3s ease-out infinite;
}

.hero-video__scroll-chevron:first-child {
    animation: move 3s ease-out 1s infinite;
}

.hero-video__scroll-chevron:nth-child(2) {
    animation: move 3s ease-out 2s infinite;
}

.hero-video__scroll-chevron:before,
.hero-video__scroll-chevron:after {
    content: ' ';
    position: absolute;
    top: 0;
    height: 100%;
    width: 51%;
    background: #fff;
}

.hero-video__scroll-chevron:before {
    left: 0;
    transform: skew(0deg, 30deg);
}

.hero-video__scroll-chevron:after {
    right: 0;
    width: 50%;
    transform: skew(0deg, -30deg);
}

@keyframes move {
    25% {
      opacity: 1;

    }
    33% {
      opacity: 1;
      transform: translateY(30px);
    }
    67% {
      opacity: 1;
      transform: translateY(40px);
    }
    100% {
      opacity: 0;
      transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
    }
}

.hero-video__scroll-text {
    display: block;
    margin-top: 75px;
    margin-left: -12px;
    font-family: ;
    font-size: 16px;
    color: #fff;
    /* text-transform: uppercase; */
    white-space: nowrap;
    opacity: .5;
    animation: pulse 2s linear alternate infinite;
}

@keyframes pulse {
    to {
      opacity: 1;
    }
}

@media (--viewport-sm-min) {
    .hero-video__content {
        width: 610px;
    }

    .hero-video__content img {
        width: 80px;
    }

    .hero-video__content h1 {
        margin: 0 0 30px;
        font-size: 42px;
    }
}

@media (--viewport-md-min) {
    .hero-video__content {
        width: 830px;
    }

    .hero-video__content img {
        width: 100px;
        margin: 0 auto 30px;
    }

    .hero-video__content h1 {
        font-size: 60px;
        letter-spacing: 2px;
    }
}

@media (--viewport-lg-min) {
    .hero-video__content {
        width: 1070px;
    }

    .hero-video__content img {
        /* width: 120px; */
    }

    .hero-video__content h1 {
        font-size: 60px;
    }
}