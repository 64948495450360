.banner-3__background {
    height: 75%;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    position: absolute;
    background-color: #f5f3ed;
}

.banner-3__container {
    padding-top: 105px;
    width: 100%;
    margin-bottom: 40px;
}

.banner-3__image img {
    width: 100%;
    height: 240px;
    object-fit: cover;
}

@media (--viewport-ms-min) {
    .banner-3__image img {
        height: 320px;
    }
}

@media (--viewport-sm-min) {
    .banner-3__container {
        padding-top: 120px;
        margin-bottom: 100px;
    }

    .banner-3__image img {
        height: 360px;
    }
}

@media (--viewport-md-min) {
    .banner-3__image img {
        height: 480px;
    }
}

@media (--viewport-lg-min) {
    .banner-3__image img {
        height: 560px;
    }
}