.banner-1__background {
    /* height: 320px; */
    height: 75%;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    position: absolute;
    background-color: #f5f3ed;
}

.banner-1__container {
    width: 100%;
    margin-bottom: 40px;
}

.banner-1__content-wrap {
    z-index: 3;
}

@media (--viewport-ms-max) {
    .banner-1__content {
        padding: 40px 0 40px;
    }
}

.banner-1__heading {
    font-size: 28px;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
    text-align: center;
    color: #000000;
    margin: 0 0 20px;
}

.banner-1__content .button {
    margin-bottom: 0;
}

.banner-1__content img {
    display: none;
}

.banner-1__image-wrap {
    z-index: 2;
}

.banner-1__image {
    /* padding: 0 15px; */
}

@media (--viewport-sm-min) {
    .banner-1__background {
        /* height: 360px; */
    }

    .banner-1__container {
        padding: 0 20px;
        padding-top: 40px;
        margin-bottom: 60px;
    }

    .banner-1__container-fix {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
    }

    .banner-1__content-wrap {
        width: 50%;
    }

    .banner-1__content {
        width: 410px;
        margin: 10px auto 0;
    }

    .banner-1__heading {
        font-size: 42px;
        text-align: left;
    }

    .banner-1__content .button {
        margin-bottom: 30px;
    }

    .banner-1__content img {
        display: block;
        width: 75%;
    }

    .banner-1__image-wrap {
        width: 50%;
    }

    .banner-1__image {
        padding: 0;
        width: 120%;
        margin-left: -20%;
    }
}

@media (--viewport-md-min) {
    .banner-1__background {
        /* height: 460px; */
    }

    .banner-1__container {
        padding: 0 20px;
        padding-top: 60px;
        margin-bottom: 80px;
    }

    .banner-1__content {
        width: 600px;
        margin: 20px auto 0;
    }

    .banner-1__heading {
        font-size: 60px;
        letter-spacing: 2px;
    }
}

@media (--viewport-lg-min) {
    .banner-1__background {
        /* height: 560px; */
    }

    .banner-1__container {
        padding: 0 20px;
        padding-top: 100px;
        margin-bottom: 100px;
    }

    .banner-1__content {
        width: 800px;
        margin: 30px auto 0;
    }

    .banner-1__heading {
        font-size: 60px;
    }
}