@media (--viewport-sm-min) {
    .masonry-item1 { grid-area: masonry-item1; }
    .masonry-item2 { grid-area: masonry-item2; }
    .masonry-item3 { grid-area: masonry-item3; }
    .masonry-item4 { grid-area: masonry-item4; }
    .masonry-item5 { grid-area: masonry-item5; }
    .masonry-item6 { grid-area: masonry-item6; }

    .masonry-tile-overzicht__grid {
        display: grid;
        grid-template-areas:
            'masonry-item1 masonry-item2'
            'masonry-item1 masonry-item4'
            'masonry-item3 masonry-item4'
            'masonry-item3 masonry-item6'
            'masonry-item5 masonry-item6';
            grid-template-rows: auto 30px auto 30px auto;
        grid-gap: 15px;
    }
}

@media (--viewport-md-min) {
    .masonry-tile-overzicht__grid {
        grid-template-areas:
            'masonry-item1 masonry-item2 masonry-item3'
            'masonry-item1 masonry-item5 masonry-item3'
            'masonry-item4 masonry-item5 masonry-item6';
        grid-template-rows: auto 50px auto; 
        grid-gap: 20px;
    }
}